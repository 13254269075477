<style>
body{
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 8px;
  background-color: #e0622a!important;
}

</style>
<template>
  <div  class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>

    <side-bar
      :sidebar-item-color="sidebarBackground"
      :sidebar-background-image="sidebarBackgroundImage"
    >
       <!-- style="background-color: black;"  -->
   

      <mobile-menu slot="content"></mobile-menu>
      <sidebar-link to="/dashboard">
        <md-icon>dashboard</md-icon>
        <p>Dashboard</p>
      </sidebar-link>

      <li v-if="checkPermission(21)" @click="changeUserDropDownState" class="md-list-item">
        <a
          class="md-list-item-router md-list-item-container md-button-clean nav-item"
          id="users"
          aria-current="page"
          ><div class="md-list-item-content md-ripple">
            <i class="md-icon md-icon-font md-theme-default">person</i>
            <p>Utilisateurs</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-chevron-down ml-4"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </div>
        </a>
      </li>
      <div v-if="usersDropDown" style="display: block" class="bg-blue m-5 w-50">
        <sidebar-link to="/utilisateurs/admins">
          <md-icon>-</md-icon>
          <p>Administrateurs</p>
        </sidebar-link>
        <sidebar-link to="/utilisateurs/vendeurs">
          <md-icon>-</md-icon>
          <p>Vendeurs</p>
        </sidebar-link>
        <sidebar-link to="/utilisateurs/acheteurs">
          <md-icon>-</md-icon>
          <p>Acheteurs</p>
        </sidebar-link>
      </div>


      <li v-if="checkPermission(21)" @click="changeInscriptionDemandsDropDownState" class="md-list-item">
        <a
            class="md-list-item-router md-list-item-container md-button-clean nav-item"
            id="users"
            aria-current="page"
        ><div class="md-list-item-content md-ripple">
          <md-icon
          ><svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="currentColor"
              class="bi bi-person-plus-fill"
              viewBox="0 0 16 16"
          >
            <path
                d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
            />
            <path
                fill-rule="evenodd"
                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
            /></svg
          ></md-icon>
          <p>Demandes d'inscription</p>
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-chevron-down ml-4"
          >
            <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            ></path>
          </svg>
        </div>
        </a>
      </li>
      <div
          v-if="InscriptionDemandDropDown"
          style="display: block"
          class="bg-blue m-5 w-50"
      >
        <sidebar-link to="/demandes/inscription/acheteurs">
          <md-icon>-</md-icon>
          <p>Acheteurs</p>
        </sidebar-link>
        <sidebar-link to="/demandes/inscription/vendeurs">
          <md-icon>-</md-icon>
          <p>Vendeurs</p>
        </sidebar-link>
      </div>

      <sidebar-link v-if="checkPermission(1)" to="/categories">
        <md-icon
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-tag-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            /></svg
        ></md-icon>
        <p>Categories</p>
      </sidebar-link>
      <sidebar-link  v-if="checkPermission(4)" to="/produits">
        <i class="md-icon md-icon-font md-theme-default"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-inboxes-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z"
                /></svg
            ></i>
          <p>Produits</p>
        </sidebar-link>
      
        <sidebar-link  v-if="checkPermission(20)" to="/sellermanagers">
          <i class="md-icon md-icon-font md-theme-default">person</i>
          <p>Managers du magazin </p>
        </sidebar-link>
      <sidebar-link v-if="checkPermission(2)" to="/sous-categories">
        <md-icon
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-tags-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M2 2a1 1 0 0 1 1-1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 2 6.586V2zm3.5 4a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
            />
            <path
              d="M1.293 7.793A1 1 0 0 1 1 7.086V2a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l.043-.043-7.457-7.457z"
            /></svg
        ></md-icon>
        <p>Sous categories</p>
      </sidebar-link>

      <!--      <sidebar-link to="/produits">-->
      <!--        <md-icon></md-icon>-->
      <!--        <p>Produits</p>-->
      <!--      </sidebar-link>-->

      <li v-if="checkPermission(3)" @click="changeProductsDropDownState" class="md-list-item">
        <a
          class="md-list-item-router md-list-item-container md-button-clean nav-item"
          id="produits"
          aria-current="page"
          ><div class="md-list-item-content md-ripple">
            <i class="md-icon md-icon-font md-theme-default"
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="currentColor"
                class="bi bi-inboxes-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1H4.98zM3.81.563A1.5 1.5 0 0 1 4.98 0h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393z"
                /></svg
            ></i>
            <p>Produits</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-chevron-down ml-4"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </div>
        </a>
      </li>
      <div
        v-if="productsDropDown"
        style="display: block"
        class="bg-blue m-5 w-50"
      >
      <sidebar-link to="/produits/nouveau">
          <md-icon>-</md-icon>
          <p>Nouveau Produit</p>
        </sidebar-link>
        <sidebar-link to="/produits/non-confirmes">
          <md-icon>-</md-icon>
          <p>à confirmer</p>
        </sidebar-link>
        <sidebar-link to="/produits/confirmes">
          <md-icon>-</md-icon>
          <p>Confirmés</p>
        </sidebar-link>
       
      </div>
      <sidebar-link  v-if="checkPermission(3)" to="/productpromotion">
      <md-icon>  <svg xmlns="http://www.w3.org/2000/svg"  width="22"
            height="22"
            fill="currentColor"
         class="bi bi-megaphone" viewBox="0 0 16 16">
      <path d="M13 2.5a1.5 1.5 0 0 1 3 0v11a1.5 1.5 0 0 1-3 0v-.214c-2.162-1.241-4.49-1.843-6.912-2.083l.405 2.712A1 1 0 0 1 5.51 15.1h-.548a1 1 0 0 1-.916-.599l-1.85-3.49a68.14 68.14 0 0 0-.202-.003A2.014 2.014 0 0 1 0 9V7a2.02 2.02 0 0 1 1.992-2.013 74.663 74.663 0 0 0 2.483-.075c3.043-.154 6.148-.849 8.525-2.199V2.5zm1 0v11a.5.5 0 0 0 1 0v-11a.5.5 0 0 0-1 0zm-1 1.35c-2.344 1.205-5.209 1.842-8 2.033v4.233c.18.01.359.022.537.036 2.568.189 5.093.744 7.463 1.993V3.85zm-9 6.215v-4.13a95.09 95.09 0 0 1-1.992.052A1.02 1.02 0 0 0 1 7v2c0 .55.448 1.002 1.006 1.009A60.49 60.49 0 0 1 4 10.065zm-.657.975 1.609 3.037.01.024h.548l-.002-.014-.443-2.966a68.019 68.019 0 0 0-1.722-.082z"/>
    </svg></md-icon>
          <p>Promotion des produits  </p>
        </sidebar-link>


      <sidebar-link v-if="checkPermission(16)" to="/demandes/adevis">
        <md-icon
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-clipboard2-plus-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"
            />
            <path
              d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5ZM8.5 6.5V8H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V9H6a.5.5 0 0 1 0-1h1.5V6.5a.5.5 0 0 1 1 0Z"
            /></svg
        ></md-icon>
        <p>Demandes de devis</p>
      </sidebar-link>
      <sidebar-link v-if="checkPermission(17)" to="/demandes/sdevis">
        <md-icon
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-clipboard2-plus-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M10 .5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5.5.5 0 0 1-.5.5.5.5 0 0 0-.5.5V2a.5.5 0 0 0 .5.5h5A.5.5 0 0 0 11 2v-.5a.5.5 0 0 0-.5-.5.5.5 0 0 1-.5-.5Z"
            />
            <path
              d="M4.085 1H3.5A1.5 1.5 0 0 0 2 2.5v12A1.5 1.5 0 0 0 3.5 16h9a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 12.5 1h-.585c.055.156.085.325.085.5V2a1.5 1.5 0 0 1-1.5 1.5h-5A1.5 1.5 0 0 1 4 2v-.5c0-.175.03-.344.085-.5ZM8.5 6.5V8H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V9H6a.5.5 0 0 1 0-1h1.5V6.5a.5.5 0 0 1 1 0Z"
            /></svg
        ></md-icon>
        <p>Demandes de devis</p>
      </sidebar-link>

  


      

<!-- Seller commands  -->
      <li v-if="checkPermission(15)" @click="changeSellerCommandDropDownState" class="md-list-item">
        <a
          class="md-list-item-router md-list-item-container md-button-clean nav-item"
          id="produits"
          aria-current="page"
          ><div class="md-list-item-content md-ripple">
            <md-icon
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-bag-check-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
            /></svg
        ></md-icon>
            <p>Commandes</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-chevron-down ml-4"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </div>
        </a>
      </li>
      <div v-if="SellerCommandsDropDown" style="display: block" class="bg-blue m-5 w-50">
        <sidebar-link to="/scommandes/confirmees">
          <md-icon>-</md-icon>
          <p>Confirmées</p>
        </sidebar-link>
        <sidebar-link to="/scommandes/pourconfirmer">
          <md-icon>-</md-icon>
          <p> À Confirmer</p>
        </sidebar-link>
        <sidebar-link to="/scommandes/refusees">
          <md-icon>-</md-icon>
          <p> Non confirmées</p>
        </sidebar-link>
    
      </div>




<!-- Admin commands  -->


      <li v-if="checkPermission(13)" @click="changeAdminCommandDropDownState" class="md-list-item">
        <a
          class="md-list-item-router md-list-item-container md-button-clean nav-item"
          id="produits"
          aria-current="page"
          ><div class="md-list-item-content md-ripple">
            <md-icon
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-bag-check-fill"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M10.5 3.5a2.5 2.5 0 0 0-5 0V4h5v-.5zm1 0V4H15v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4h3.5v-.5a3.5 3.5 0 1 1 7 0zm-.646 5.354a.5.5 0 0 0-.708-.708L7.5 10.793 6.354 9.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0l3-3z"
            /></svg
        ></md-icon>
            <p>Commandes</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-chevron-down ml-4"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </div>
        </a>
      </li>
      <div v-if="AdminCommandsDropDown" style="display: block" class="bg-blue m-5 w-50">
        <sidebar-link to="/acommandes/confirmees">
          <md-icon>-</md-icon>
          <p>Confirmées</p>
        </sidebar-link>
        <sidebar-link to="/acommandes/pourconfirmer">
          <md-icon>-</md-icon>
          <p>À Confirmer</p>
        </sidebar-link>
        <sidebar-link to="/acommandes/refusees">
          <md-icon>-</md-icon>
          <p> Non confirmées</p>
        </sidebar-link>
      </div>

      <li v-if="checkPermission(8)" @click="changeBrandsDropDownState" class="md-list-item">
        <a
          class="md-list-item-router md-list-item-container md-button-clean nav-item"
          id="users"
          aria-current="page"
          ><div class="md-list-item-content md-ripple">
            <md-icon
              ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            fill="currentColor"
            class="bi bi-tag-fill"
            viewBox="0 0 16 16"
          >
            <path
              d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"
            /></svg
        ></md-icon>
            <p>Marque</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              class="bi bi-chevron-down ml-4"
            >
              <path
                fill-rule="evenodd"
                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
              ></path>
            </svg>
          </div>
        </a>
      </li>

      <div
        v-if="BrandsDropDown"
        style="display: block"
        class="bg-blue m-5 w-50"
      >
        <sidebar-link to="/marques/confirmees">
          <md-icon>-</md-icon>
          <p>Confirmés </p>
        </sidebar-link>
       
        <sidebar-link to="/marques/pourconfirmer">
          <md-icon>-</md-icon>
          <p>à Confirmer</p>
        </sidebar-link>
         <sidebar-link to="/marques/non-confirmees">
          <md-icon>-</md-icon>
          <p>Non Confirmés</p>
        </sidebar-link>



      </div>
      <sidebar-link v-if="checkPermission(27)" to="/banners">
        <md-icon
        >
          <svg xmlns="http://www.w3.org/2000/svg"  width="22"
               height="22" fill="currentColor" class="bi bi-pip" viewBox="0 0 16 16">
            <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
            <path d="M8 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-.5.5h-5a.5.5 0 0 1-.5-.5v-3z"/>
          </svg>
        </md-icon>
        <p>Bannières</p>
      </sidebar-link>


    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <fixed-plugin
        :color.sync="sidebarBackground"
        :image.sync="sidebarBackgroundImage"
      >
      </fixed-plugin>

      <dashboard-content> </dashboard-content>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "@/pages/Layout/MobileMenu.vue";
import FixedPlugin from "./Extra/FixedPlugin.vue";

import { store } from "../../store/modules/GeneralStore";

export default {
  components: {
    TopNavbar,
    DashboardContent,
    ContentFooter,
    MobileMenu,
    FixedPlugin,
  },

  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    CurrUser() {
      return store.state.user;
    },
    usersDropDown() {
      return store.state.usersDropDown;
    },
    InscriptionDemandDropDown() {
      return store.state.inscriptionDemandDropDown;
    },
    productsDropDown() {
      return store.state.productsDropDown;
    },
    BrandsDropDown() {
      return store.state.brandDropDown;
    },
    AdminCommandsDropDown() {
      return store.state.admincommandsDropDown;
    },
    SellerCommandsDropDown() {
      return store.state.sellercommandsDropDown;
    },
  },
  data() {
    return {
      sidebarBackground: "orange",
      sidebarBackgroundImage: "",
    };
  },
  methods: {
    checkPermission(per_id){
      let can = false;
      for (let i=0;i<store.state.user.permissions.length;i++){
        if (store.state.user.permissions[i].id===per_id){
          can = true;
        }
      }
      return can;
    },

    changeUserDropDownState() {
      store.state.usersDropDown = !store.state.usersDropDown;
    },
    changeAdminCommandDropDownState() {
      store.state.admincommandsDropDown = !store.state.admincommandsDropDown;
    },
    changeSellerCommandDropDownState() {
      store.state.sellercommandsDropDown = !store.state.sellercommandsDropDown;
    },
    changeInscriptionDemandsDropDownState() {
      store.state.inscriptionDemandDropDown =
        !store.state.inscriptionDemandDropDown;
    },
    changeBrandsDropDownState() {
     
     store.state.brandDropDown=
       !store.state.brandDropDown;
   },
    changeProductsDropDownState() {

      store.state.productsDropDown = !store.state.productsDropDown;
    },
  },
};
</script>
