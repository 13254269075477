<style>
select[name="example_length"]{
  padding-right: 18px!important;
}
.dataTables_length{
  padding-bottom: 20px!important;
}
.divcontainer {
  overflow-x: scroll;
  overflow-y: auto;
  transform: rotateX(180deg);
}

.divcontainer table {
  transform: rotateX(180deg);
}
::-webkit-scrollbar { height: 8px;}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #e0622a;
}
</style>
<style>
.progress-container {
  width: 100%;
  height: 20px;
  background-color: #f3f3f3;
  position: relative;
  overflow: hidden;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #e0622a;
  position: absolute;
  animation: progressAnimation 2s linear infinite;
}

@keyframes progressAnimation {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>
<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>

      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>

    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
<div class="w-full text-end"></div>
        <router-link :to="{ name: 'Produits / Nouveau' }">
          <md-button
            class="md-raised"
            style="background-color: #1476b5 !important"
        >Nouveau</md-button
        >
        </router-link>

        <md-button @click="hideImportModal"
        class="md-raised mr-5 "
            style="background-color: #e0622a !important"
        >Importer</md-button>
        <p><a :href="appUrl+'/assets/seed/ExcelExampleProductsImport.xlsx'" download>Obtenir l'exemple de format de fichier excel de base depuis ici !</a></p>
        <div
            style="padding: unset !important"
            class="container col-12 w-100 bg-danger"
        >
          <div class="w-100" style="width: 100% !important">
            <div
                class="flex flex-row justify-between w-full w-100 mb-1 sm:mb-0"
                style="width: 100% !important"
            >
              <h2 class="text-2xl leading-tight"></h2>
          
            </div>
            <div class="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
              <div
                  class="inline-block min-w-full overflow-hidden rounded-lg shadow"
              >
               
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
          class="relative z-10"
          style="z-index: 50000"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
          :hidden="!DeleteModalOn"
      >
        <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        ></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div
              class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <div
                class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
            >
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div
                      class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
                  >
                    <svg
                        class="h-6 w-6 text-red-600"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                    >
                      <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                      />
                    </svg>
                  </div>
                  <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <h3
                        class="text-base font-semibold leading-6 text-gray-900"
                        id="modal-title"
                    >
                      Supprimer produit
                    </h3>
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        vous êtes sur que vous voulez supprimer ce produit
                        ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                  class="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
              >
                <button
                    @click="supprimer(currentCategory)"
                    :class="{ 'disabled:opacity-70': isLoading }"
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    :disabled="isLoading"
                >
                  <svg
                      v-if="isLoading"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="mr-2 animate-spin"
                      viewBox="0 0 1792 1792"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                        d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                    ></path>
                  </svg>
                  Supprimer
                </button>
                <button
                    @click="changeDeleteModalState(null)"
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="relative z-10 " style="z-index: 50000" aria-labelledby="modal-title" role="dialog" aria-modal="true" :hidden="!importModalOn">
        <!--
          Background backdrop, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <!--
              Modal panel, show/hide based on modal state.

              Entering: "ease-out duration-300"
                From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                To: "opacity-100 translate-y-0 sm:scale-100"
              Leaving: "ease-in duration-200"
                From: "opacity-100 translate-y-0 sm:scale-100"
                To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            -->
            <div  class="flex items-center justify-center relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all ">
              <div class="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4" >
                <div
                    id="importProgress"
                    style="display: none"
                >

                  <div>
                    <div class=" p-4 m-auto rounded-lg shadow w-72">
                      <div>
            <span class="text-xs font-light inline-block py-1 px-2 uppercase rounded-full  " style="color:#1476b5!important" >
                Import Progresse
            </span>
                      </div>
<!--                      <div class="w-full h-4 bg-gray-400 rounded-full mt-3">-->
<!--                        <div-->
<!--                            :style="{'width': ImportProgress }"-->
<!--                            class=" h-full text-center text-xs text-white  rounded-full" style="background-color:#1476b5!important">-->
<!--                          {{ImportProgress}}-->
<!--                        </div>-->
<!--                      </div>-->

                      <div class="progress-container">
                        <div class="progress-bar"></div>
                      </div>
                      <div v-if="AnyErrors" class="text-red-600 w-full mb-4 p-4" role="alert">
                        <p class="font-bold">
                          {{AnyErrors }}
                        </p>
                      </div>
                    </div>
                  </div>

                </div>
                <form @submit.prevent id="importForm" style="width: 450px;" class="">
                  <div   class="mt-4 pb-5 flex text-sm leading-6 text-gray-600">
                    <label
                        for="file-upload"
                        class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span class="drop-zone__prompt" id="drop-zone" @click="importProcess">Importer un fichier EXCEL </span>
                      <input
                          id="real_input"
                          name="file-upload"
                          type="file"
                          class="sr-only"
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                    </div>
                  <label style="font-weight: bold;color: black" class="pb-5">Images</label>
                  <vue-upload-multiple-image
                      class="pb-5"
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @edit-image="editImage"
                      :data-images="images"
                      dragText="Sélectionner les images de produit"
                      browseText="Naviguer"
                      primaryText="Principale"
                      markIsPrimaryText="Definir comme principale"
                      popupText="pop"
                      dropText="drop"
                      idUpload="myIdUpload"
                      editUpload="myIdEdit"
                      :maxImage=5000
                  ></vue-upload-multiple-image>
                  <button  style="display: none" id="importbtn"    :class="{'disabled:opacity-70': isLoading,}" type="submit" class=" mt-2 inline-flex w-full justify-center rounded-md bg-gray-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 sm:ml-3 sm:w-auto" :disabled="isLoading">
                    <svg v-if="isLoading" width="20" height="20" fill="currentColor" class="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                      <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                      </path>
                    </svg>
                    Import</button>
                  <button  @click="hideImportModal" type="button" class="mt-3 mt-2 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
                  <div  class="text-center" style="color: darkred;display: none" id="error_response"></div>
                </form>
<!--                <input type="file" id="real_input" name="myFile" class="drop-zone__input">-->
              </div>
              <!--          <div class="h-100" v-else >-->
              <!--            <div class="flex flex-col flex-1 gap-3 p-4">-->
              <!--              <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl p-4">-->
              <!--              </div>-->
              <!--              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl p-4">-->
              <!--              </div>-->
              <!--              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl p-4">-->
              <!--              </div>-->
              <!--            </div>-->
              <!--          </div >-->
            </div>
          </div>
        </div>
      </div>


      <!--      <div-->
      <!--        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"-->
      <!--      >-->
      <!--        <md-card class="md-card-plain">-->
      <!--          <md-card-header data-background-color="green">-->
      <!--            <h4 class="title">Table on Plain Background</h4>-->
      <!--            <p class="category">Here is a subtitle for this table</p>-->
      <!--          </md-card-header>-->
      <!--          <md-card-content>-->
      <!--            <ordered-table></ordered-table>-->
      <!--          </md-card-content>-->
      <!--        </md-card>-->
      <!--      </div>-->
      <div class="flex w-full p-4" style="margin-top: -50px!important;">
        <div class="w-1/2 sm:w-full p-2">
          <div class="mt-2">
            <input
                v-model="reference_product"
                type="text"
                name="reference_tawdaw"
                placeholder="Référence de Produit"
                id="reference_tawdaw"
                autocomplete="given-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
          </div>
        </div>
        <div class="w-1/2 sm:w-full p-2">
          <div class="mt-2">
            <select v-model="vendor_id"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
<!--              <optgroup v-for="seller in sellers" :label="'Catégorie - ' + category.name_cat_fr" :key="category.id">-->
                <option value="tawdaw">Tawdaw</option>
                <option v-for="seller in sellers" :value="seller.user.id" :key="seller.user.id">{{ seller.user.name }}</option>
<!--              </optgroup>-->
            </select>
<!--            <input-->
<!--                type="text"-->
<!--                placeholder="Vendeur"-->
<!--                name="reference_tawdaw"-->
<!--                id="reference_tawdaw"-->
<!--                autocomplete="given-name"-->
<!--                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
<!--            />-->
          </div>
        </div>
      </div>

      <div class="flex w-full p-4" style="margin-top: -30px!important;">
        <div class="w-1/2 sm:w-full p-2">
          <div class="">
            <select v-model="sub_category_id"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <optgroup v-for="category in categories" :label="'Catégorie - ' + category.name_cat_fr" :key="category.id">
                <option v-for="subCategory in category.subcategories" :value="subCategory.id" :key="subCategory.id">{{ subCategory.name_scat_fr }}</option>
              </optgroup>
            </select>
<!--            <input-->
<!--                type="text"-->
<!--                placeholder="Sous categorie"-->
<!--                name="reference_tawdaw"-->
<!--                id="reference_tawdaw"-->
<!--                autocomplete="given-name"-->
<!--                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
<!--            />-->
          </div>
        </div>
        <div class="w-1/2 sm:w-full p-2"></div>
      </div>

        <md-button
            @click="filter()"
            class="md-raised ml-4 "
            style="background-color: #1476b5 !important;margin-top: -5px!important;margin-left: 20px!important;"
        >Filtrer</md-button
        >
      <md-button
          @click="clear()"
          class="md-raised ml-4 "
          style="background-color: #e0622a !important;margin-top: -5px!important;margin-left: 20px!important;"
      >Clear</md-button
      >


      <md-card>
        <md-card-header style="background-color: #e0622a !important">
          <h4 class="title">Liste des produits confirmés</h4>
        </md-card-header>
        <md-card-content>
          <!--            <simple-table :tableData="categories" ></simple-table>-->
          <div class="divcontainer">
            <md-table  style="text-align: center" v-model="confirmedProducts" table-header-color="orange;">
              <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Image">
                  <img style="height: 100px!important;width: 200px!important;" :src="item.image?item.image.original_url:noe_image"/>
                </md-table-cell>
                <md-table-cell md-label="Référence TAWDAW">{{
                    item.reference_tawdaw
                  }}</md-table-cell>
                  <md-table-cell md-label="Référence du produit">{{
                    item.reference_product
                  }}</md-table-cell>
                <md-table-cell md-label="Nom">{{
                    item.name_product
                  }}</md-table-cell>
                <md-table-cell md-label="Type">{{
                    item.type_price
                  }}</md-table-cell>
                <md-table-cell md-label="Qte en stock">{{
                    item.qte_stock
                  }}</md-table-cell>
                <md-table-cell md-label="Qte min vente">{{
                    item.qte_min_vente??"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Sous categorie">{{
                    item.sub_category!==null?item.sub_category.name_scat_fr:"Sans categorie"
                  }}</md-table-cell>
                <md-table-cell md-label="Marque">{{
                    item.brand!==null?item.brand.brand_name:"Vide"
                  }}</md-table-cell>
                <md-table-cell md-label="Createur">{{
                    item.creator.name
                  }}</md-table-cell>
                <md-table-cell class="items-center " md-label="Action">
                
                  <router-link class="m-auto "  :to="`/produit/update/${item.id}`">
                  <svg
                         style="color:black"

                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil-square"
                      viewBox="0 0 16 16"
                  >
                    <path
                        d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
                    />
                    <path
                        fill-rule="evenodd"
                        d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                    />
                  </svg>
                </router-link >

                  <svg @click="changeDeleteModalState(item.id)" :id="item.id" style="cursor: pointer"  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="delete ml-3 bi bi-trash2-fill" viewBox="0 0 16 16">
                    <path d="M2.037 3.225A.703.703 0 0 1 2 3c0-1.105 2.686-2 6-2s6 .895 6 2a.702.702 0 0 1-.037.225l-1.684 10.104A2 2 0 0 1 10.305 15H5.694a2 2 0 0 1-1.973-1.671L2.037 3.225zm9.89-.69C10.966 2.214 9.578 2 8 2c-1.58 0-2.968.215-3.926.534-.477.16-.795.327-.975.466.18.14.498.307.975.466C5.032 3.786 6.42 4 8 4s2.967-.215 3.926-.534c.477-.16.795-.327.975-.466-.18-.14-.498-.307-.975-.466z"/>
                  </svg>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </div>
        </md-card-content>
      </md-card>

    </div>
      <md-button
          v-if="curPage!==1"
          class="md-raised"
          style="background-color: #1476b5 !important"
          @click="previousPage()"
      ><<</md-button
      >
      <md-button
          class="md-raised"
          style="background-color: #1476b5 !important"
          v-if="curPage!==totalPages"
          @click="nextPage()"
      >>></md-button>
    <br>
    page {{curPage}} sur {{totalPages}}
  </div>
</template>

<script>
//import { SimpleTable, OrderedTable } from "@/components";
import { store } from "../../store/modules/GeneralStore.js";
import $ from "jquery";
import jQuery from "jquery";
import DataTable from 'datatables.net-dt';
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
import * as XLSX from "xlsx";
import VueUploadMultipleImage from 'vue-upload-multiple-image';


export default {
  components: {
    VueUploadMultipleImage,
    //OrderedTable,
    //SimpleTable,
  },
  data() {
    return {
      images: [],
      DeleteModalOn: false,
      currentProduct: null,
      importModalOn: false,
      noe_image: require("@/assets/img/null.png"),
      //filtred : false,
      sub_category_id: null,
      vendor_id: null,
      reference_product: null,
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    confirmedProducts() {
      return store.state.confirmedProducts;
    },
    ImportProgress(){
      return store.state.importProgress;
    },
    appUrl(){
      return store.state.urlPrefix;
    },
    curPage(){
      return store.state.curPage;
    },
    totalPages(){
      return store.state.totalPages;
    },
    categories(){
      return store.state.categories;
    },
    sellers(){
      return store.state.sellers;
    },
  },
  mounted: function () {
    let context = this;
    if (sessionStorage.getItem("productImportSuccess")){
      context.$toasted.success(sessionStorage.getItem("productImportSuccess"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productImportSuccess");
    }
    if (sessionStorage.getItem("productImportError")){
      context.$toasted.error(sessionStorage.getItem("productImportError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("productImportError");
    }
    //store.dispatch("getConfirmedProducts",{page:1});
    store.dispatch("filterConfirmedProducts",{page:1});
    store.dispatch("getCategoriesWithSubCategories");
    store.dispatch("getSellers");
  },
  methods: {
    nextPage(){
      store.dispatch("filterConfirmedProducts",{page:(this.curPage+1),reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,creator_id:this.vendor_id});
    },
    previousPage(){
      store.dispatch("filterConfirmedProducts",{page:(this.curPage-1),reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,creator_id:this.vendor_id});
    },
    changeDeleteModalState(product_id) {
      this.DeleteModalOn = !this.DeleteModalOn;
      this.currentCategory = product_id;
    },
    filter(){
      store.dispatch("filterConfirmedProducts",{page:1,reference_product:this.reference_product,
        sub_category_id:this.sub_category_id,creator_id:this.vendor_id});
    },
    clear(){
      this.reference_product=null;
      this.sub_category_id=null;
      this.vendor_id=null;
      store.dispatch("filterConfirmedProducts",{page:1});
    },
    supprimer(product_id) {
      let context = this;
      this.DeleteModalOn = !this.DeleteModalOn;
      store.dispatch("deleteProduct", product_id).then(
          function () {
            if (sessionStorage.getItem("productSuccess")){
              context.$toasted.success(sessionStorage.getItem("productSuccess"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("productSuccess");
            }
            if (sessionStorage.getItem("productError")){
              context.$toasted.error(sessionStorage.getItem("productError"),{
                duration: 5000,
                position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
                theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
                iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
              });
              sessionStorage.removeItem("productError");
            }          }
      );
    },
    hideImportModal(){
      this.importModalOn = !this.importModalOn;
    },
    uploadImageSuccess(formData, index, fileList) {
      //console.log('data', formData, index, fileList)
      this.images = fileList;
      // //Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove (index, done, fileList) {
      done()
    },
    editImage (formData, index, fileList) {
      console.log('edit data', formData, index, fileList)
    },
    importProcess(){
      let context = this;
        const real=document.getElementById("real_input");
        const fake=document.getElementById("drop-zone");
      let selectedFile;
      real.click();
      $('#real_input').change(function () {
        $('#error_response').css.display = "none";
        $('#importbtn').css("display","");
        $('.drop-zone__prompt').html(this.files.length + " file(s) selected");
        selectedFile = this.files[0];
      });


      $('#importbtn').click(function (){
        if(selectedFile) {
         // store.dispatch('changeImportProgress','0%')
          $('#importForm').css("display","none");
          $('#importProgress').css("display","");
          let fileReader = new FileReader();
          fileReader.readAsBinaryString (selectedFile);
          fileReader.onload = (event) =>{
            let data = event.target.result;
            let workbook = XLSX.read(data,{type:'binary'});
            workbook.SheetNames.forEach(sheet => {
              let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
             store.dispatch('importProduct',{rowObject:rowObject,images :context.images});
              console.log(rowObject);
            })
          }

        }
        else {
          $('#error_response').html("file is required");
          $('#error_response').css.display = "";
        }
      });

    }
  },
};
</script>
<style>
/* table's header style */
th{
  text-align: center!important;
}
.drop-zone {
  height: 200px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #646cff;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}


</style>

