<template>
  <form class="p-5" @submit.prevent>
    <div class="space-y-12 p-5 bg-white">


      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          Sous Categorie
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          Les champs avec la signe <span class="required">*</span> sont obligatoires !
        </p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="sm:col-span-3">
            <label
              for="first-name"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Nom en francais <span class="required">*</span></label
            >
            <div class="mt-2">
              <input
                type="text"
                v-model="subCategory.nom_fr"
                name="nom-fr"
                id="nom-fr"
                autocomplete="given-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Nom en arabe</label
            >
            <div class="mt-2">
              <input
                type="text"
                v-model="subCategory.nom_ar"
                name="nom-ar"
                id="nom-ar"
                autocomplete="family-name"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div class="sm:col-span-3">
            <label
              for="last-name"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Catégorie  <span class="required">*</span></label
            >
            <div class="mt-2">
              <select
                v-model="subCategory.category_id"
                name="cat"
                id="cat"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option
                  v-for="item in categories"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name_cat_fr }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="border-b border-gray-900/10 pb-12">
        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
          <div class="col-span-full">
            <label
              for="cover-photo"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Image</label
            >
            <div
              class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
            >
              <div
                v-if="subCategory.selectedImage === null"
                class="text-center"
              >
                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                  <label
                    for="file-upload"
                    class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                  >
                  <span style="color: #e0622a !important">Importer media</span>
                    <input
                      @change="onImageChanged"
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      class="sr-only"
                    />
                  </label>
                  <!--                  <p class="pl-1">or drag and drop</p>-->
                </div>
                <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
              </div>
              <div v-else>
                <img style="height: 300px !important" id="catImg" src="" />
                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-black focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span style="color: #e0622a !important">Importer une image</span>
                      <input
                        @change="onImageChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <!-- <a
        href="/sous-categories"
        type="button"
        class="text-sm font-semibold leading-6 text-gray-900"
        >Annuler</a
      > -->
      <router-link to="/sous-categories">
        <md-button style="background-color: #e0622a !important"  >Annuler</md-button></router-link>



      <md-button
        :class="{ 'disabled:opacity-70': isLoading }"
        @click="create"
        type="submit"
        :disabled="isLoading"
        class="md-raised"
          style="background-color: #1476b5 !important"         >
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            v-if="isLoading"
            width="20"
            height="20"
            fill="currentColor"
            class="mr-2 animate-spin"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
            ></path>
          </svg>
        </span>
        Créer
      </md-button>
    </div>
  </form>
</template>

<script>
// import { PhotoIcon, UserCircleIcon } from "@heroicons/vue/24/solid";

import { store } from "@/store/modules/GeneralStore";
import $ from "jquery";
//import Select2 from 'v-select2-component';

export default {
  components: {
    //Select2
  },
  data() {
    return {
      subCategory: {
        nom_fr: null,
        nom_ar: null,
        category_id: null,
        selectedImage: null,
      },
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    categories() {
      return store.state.categories;
    },
  },

  mounted: function () {
    store.dispatch("getAllCategories");
  },
  methods: {
    onImageChanged(event) {
      this.subCategory.selectedImage = event.target.files[0];
      const image = this.subCategory.selectedImage;
      const reader = new FileReader();
      reader.onload = () => {
        const imgUrl = reader.result;
        document.getElementById("catImg").setAttribute("src", imgUrl);
        console.log(imgUrl);
        this.subCategory.selectedImage = imgUrl;
      };
      reader.readAsDataURL(image);
    },
    create() {
      let context = this;
      store.dispatch("addSubCategory", {
        name_scat_fr: this.subCategory.nom_fr,
        name_scat_ar: this.subCategory.nom_ar,
        image_url: this.subCategory.selectedImage,
        category_id: this.subCategory.category_id,
      }).then(function (){
        if (sessionStorage.getItem("subCategorySuccess")){
          context.$toasted.success(sessionStorage.getItem("subCategorySuccess")+" : <a style='color : black;margin-left: 10px' href='/sous-categories'>consulter la liste des sous categories ici</a>",{
            duration: 5000,
            position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
            theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
            iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
          });
          sessionStorage.removeItem("subCategorySuccess");
          context.subCategory= {
            nom_fr: null,
                nom_ar: null,
                category_id: null,
                selectedImage: null,
          };
        }
        if (sessionStorage.getItem("subCategoryError")){
          context.$toasted.error(sessionStorage.getItem("subCategoryError"),{
            duration: 5000,
            position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
            theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
            iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
          });
          sessionStorage.removeItem("subCategoryError");
        }
      });
    },
  },
};
</script>

<style >
.required {
  font-weight: bolder;
  color :red;
  /* You can also add more styles here if needed */
}
</style>