<template>
    <div>
        <div class="w-full text-end">
        <router-link class="m-auto"  v-if="showButtonconfirmed"   @click="handlButton()" to="/scommandes/confirmees">
          <md-button
        
              class="md-raised mr-10"
              style="background-color: #e0622a !important"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
                </md-button>
            </router-link>
            <router-link class="m-auto" v-if="showButtontoconfirm"   @click="handlButt()" to="/scommandes/pourconfirmer">
          <md-button
              class="md-raised mr-10"
             
              style="background-color: #e0622a !important"
            ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                </svg>
                </md-button>
            </router-link>
        </div>
        
    <div class="p-9" > 
     <div class="space-y-12 p-5 bg-white ">
        <div class="mt-8">
                      <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                          <li v-for="item in confirmedsellercommand.cmd_products" :key="item.id" class="flex py-9">
                            <div class="h-36 w-40 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                              <img  style="height: 9rem!important;width: 10rem!important;" :src="(item.product.media && item.product.media.length>0)? (item.product.media[0].original_url?item.product.media[0].original_url: noe_image):noe_image"  />
                            </div>

                            <div class="ml-4 flex flex-1 flex-col">
                              <div>
                                <div class="flex flex-col lg:flex-row ">
                                    <div>
                                  <h1 class="font-black text-xl">

                                   {{ item.product.name_product }}
                                   {{ item.product.id }}
                                  </h1>
                                  <div class="mt-5 ml-3">
                                  <!-- <p class="text-xs ">Référence TAWDAW :<span class="font-bold text-xs ">  {{ item.product.reference_tawdaw }}</span></p> -->
                                  <p class="text-xs ">Référence du produit :<span class="font-bold text-xs ">  {{ item.product.reference_product }}</span></p>
                                  <p class="text-xs ">Description : {{ item.product.description }}</p>

                                </div>
                                </div>
                                 <div class="flex flex-1  justify-between text-sm">
                                   

                                 
                                </div>
                                <div>
                                    <p class="ml-7" v-if="item.product.type_price=='priced'"> Prix Réel : {{ item.product.products_with_price.reel_price}} DH </p>
                                  <p class=" ml-7" v-if="item.product.type_price=='priced'"> Prix du Promotion : {{ item.product.products_with_price.virtual_price}} DH </p>
                                </div>
                                <p class=" ml-7" v-if="item.product.type_price=='not_priced'"> Sans prix </p>
                                </div>
                            
                              </div>
                              
                              <div class="flex flex-1 items-end justify-between text-sm">
                                <p class="text-gray-500">Quantité du produit :  {{ item.qte_cmd }}</p>

                                <!-- <div class="flex">
                                  <button type="button" class="font-medium text-indigo-600 hover:text-indigo-500">Remove</button>
                                </div> -->
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
     </div>
    </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";

export default {
    
    mounted: function () {
        this.id_cmd = this.$route.params.id;
        let cc= this.$route.path
        console.log(cc)
        console.log(this.id_cmd)
        let context= this; 
        let payload={
            user_id: store.state.user.id, 
            id_cmd:  this.id_cmd
        }
    store.dispatch("getConfirmedSellerCommandById",payload).then(function (){
        console.log(context.confirmedsellercommand)
    })
    if (this.$route.path.includes('/scommandes/pourconfirmer/details')) {
      this.showButtontoconfirm = true;
    }
    if (this.$route.path.includes('/scommandes/confirmees/details')) {
      this.showButtonconfirmed = true;
    }
    },
    data() {
      return {
        noe_image: require("@/assets/img/null.png"),

        id_cmd : null, 
        showButtonconfirmed: false, 
        showButtontoconfirm: false
    
      };
    },
    computed: {
   
    confirmedsellercommand() {
      return store.state.confirmedsellercommand;
    },
    handlButton(){
        this.showButtonconfirmed = false;
      
 
    },
    handlButt(){
        this.showButtontoconfirm = false;
      
 
    }
  },
}
</script>

<style>

</style>