//components
import DashboardLayout from "@/pages/Layout/DashboardLayout.vue";
import Dashboard from "@/pages/Dashboard.vue";
import AdminProfile from "@/pages/users/admins/AdminProfile";
import CategoriesList from "@/pages/categories/CategoriesList.vue";
import SubCatListByCat from "@/pages/sub-categories/SubCatListByCat.vue";
import SubCategoriesList from "@/pages/sub-categories/SubCategoriesList.vue";
import UnconfirmedProducts   from "@/pages/products/UnconfirmedProducts.vue";
import ConfirmedProducts   from "@/pages/products/ConfirmedProducts.vue";
import CreateSellerProduct   from "@/pages/products/CreateSellerProduct.vue";
import AdminsList from "@/pages/users/admins/AdminsList.vue";
import SellersList from "@/pages/users/sellers/SellersList.vue";
import SellerManagerList from "@/pages/users/sellers/SellerManagerList.vue";
import BuyersList from "@/pages/users/buyers/BuyersList.vue";
import CreateCategory from "@/pages/categories/CreateCategory.vue";
import UpdateCategory from "@/pages/categories/UpdateCategory.vue";
import CreateSubCategory from "@/pages/sub-categories/CreateSubCategory.vue";
import UpdateSubCategory from "@/pages/sub-categories/UpdateSubCategory.vue";
import CreateAdmin from "@/pages/users/admins/CreateAdmin.vue";
import UpdateAdmin from "@/pages/users/admins/UpdateAdmin.vue";
import CreateNewSeller from "@/pages/users/sellers/CreateNewSeller.vue";
import CreateSeller from "@/pages/users/sellers/CreateSeller.vue";
import CreateNewBuyer from "@/pages/users/buyers/CreateNewBuyer.vue";
import CreateBuyer from "@/pages/users/buyers/CreateBuyer.vue";
import CreateProduct from "@/pages/products/CreateProduct.vue";
import ProductsPromotion from "@/pages/products/ProductsPromotion.vue";
import UpdateProduct from "@/pages/products/UpdateProduct.vue";
import SellerProducts from "@/pages/products/SellerProducts.vue";
import InscrDemandsBuyers from "@/pages/users/buyers/InscrDemandsBuyers.vue";
import UpdateSellerManager from "@/pages/users/sellers/UpdateSellerManager.vue";
import CreateSellerManager from "@/pages/users/sellers/CreateSellerManager.vue";
import InscrDemandsSellers from "@/pages/users/sellers/InscrDemandsSellers.vue";
import Typography from "@/pages/Typography.vue";
import Icons from "@/pages/Icons.vue";
import Maps from "@/pages/Maps.vue";
import Notifications from "@/pages/Notifications.vue";
import UpgradeToPRO from "@/pages/UpgradeToPRO.vue";
import Login from "@/pages/auth/Login.vue";
import Register from "@/pages/auth/Register.vue";
import Thanks from "@/pages/auth/ThankYou.vue";
import CreateBrand from "@/pages/brands/CreateBrand.vue";
import authGuard from "./guards/guardByAuthState/authGuard";
import canManageUsers from "./guards/guardByPermission/canManageUsers";
import unAuthGuard from "./guards/guardByAuthState/unAuthGuard.js";
import mustBeAdmin from "./guards/guardByRole/mustBeAdmin.js";
import mustNotBeBuyer from "./guards/guardByRole/mustNotBeBuyer.js";
import ConfirmedBrandsList from "@/pages/brands/ConfirmedBrandsList.vue";
import PendingBrandsList from "@/pages/brands/PendingBrandsList.vue";
import NonConfirmedBrandsList from "@/pages/brands/NonConfirmedBrandsList.vue";
import UpdateBrand from "@/pages/brands/UpdateBrand.vue";
import UpdateSeller from "@/pages/users/sellers/UpdateSeller.vue";
import UpdateBuyer from "@/pages/users/buyers/UpdateBuyer.vue";
import ConfirmedCommandsSeller from "@/pages/commands/ConfirmedCommandsSeller.vue";
import UnConfirmedSellerCommand from "@/pages/commands/UnConfirmedSellerCommand.vue";
import UnConfirmedAdminCommand from "@/pages/commands/UnConfirmedAdminCommand.vue";
import PendingCommandsAdmin from "@/pages/commands/PendingCommandsAdmin.vue";
import ConfirmedCommandsAdmin from "@/pages/commands/ConfirmedCommandsAdmin.vue";
import PendingCommandsSeller from "@/pages/commands/PendingCommandsSeller.vue";
import SellerCommandDetails from "@/pages/commands/SellerCommandDetails.vue";
import AdminCommandDetails from "@/pages/commands/AdminCommandDetails.vue";
import CreateCommandSeller from "@/pages/commands/CreateCommandSeller.vue";
import CreateCommandAdmin from "@/pages/commands/CreateCommandAdmin.vue";
import Banners from "@/pages/Banners/Banners.vue";

import AdminDevis from "@/pages/devis/AdminDevis.vue";
import SellerDevis from "@/pages/devis/SellerDevis.vue";
//guards
import canManageCategories from "./guards/guardByPermission/canManageCategories.js";
import canManageSubCategories from "./guards/guardByPermission/canManageSubCategories.js";
import canManageProducts from "./guards/guardByPermission/canManageProducts.js";
import canManageOwnProducts from "./guards/guardByPermission/canManageOwnProducts.js";
import canManageSellersManagers from "./guards/guardByPermission/canManageSellersManagers.js";
import canManageAdminCommands from "./guards/guardByPermission/canManageAdminCommands.js";
import canManageSellerCommands from "./guards/guardByPermission/canManageSellerCommands.js";
import canManageAdminDevis from "./guards/guardByPermission/canManageAdminDevis.js";
import canManageSellerDevis from "./guards/guardByPermission/canManageSellerDevis.js";
import canManageBunners from "@/routes/guards/guardByPermission/canManageBunners";

export default [
  {
    path: "/login",
    name: "Connexion",
    component: Login,
    beforeEnter: unAuthGuard,
  },
  {
    path: "/inscription",
    name: "Inscription",
    component: Register,
    beforeEnter: unAuthGuard,
  },
  {
    path: "/apres_inscription",
    name: "Merci",
    component: Thanks,
    beforeEnter: unAuthGuard,
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    beforeEnter: authGuard,
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: Dashboard,
      },
  
      {
        path: "/demandes/sdevis",
        name: "Demandes de Devis",
        component: SellerDevis,
        beforeEnter: canManageSellerDevis,
      },
      {
        path: "/demandes/adevis",
        name: "Demandes de Devis",
        component: AdminDevis,
        beforeEnter: canManageAdminDevis,
      },
      {
        path: "acommandes/confirmees",
        name: "Commandes Confirmées",
        component: ConfirmedCommandsAdmin,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "acommandes/confirmees/details",
        name: "Commandes Confirmées / Détails du commande",
        component: AdminCommandDetails,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "scommandes/confirmees/details/:id",
        name: "Commandes Confirmées / Détails du commande",
        component: SellerCommandDetails,
        beforeEnter: canManageSellerCommands,
      },
      {
        path: "acommandes/confirmees/details/:id",
        name: "Commandes Confirmées / Détails du commande",
        component: AdminCommandDetails,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "acommandes/pourconfirmer",
        name: "Commandes À Confirmer",
        component: PendingCommandsAdmin,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "scommandes/confirmees",
        name: "Commandes Confirmées",
        component: ConfirmedCommandsSeller,
        beforeEnter: canManageSellerCommands,
      },
      {
        path: "scommandes/confirmees/nouveau",
        name: "Commandes Confirmées / Nouvelle Commande ",
        component: CreateCommandSeller,
        beforeEnter: canManageSellerCommands,
      },
      {
        path: "acommandes/confirmees/nouveau",
        name: "Commandes Confirmées / Nouvelle Commande ",
        component: CreateCommandAdmin,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "scommandes/pourconfirmer/details/:id",
        name: "Commandes À Confirmer / Détails du commande",
        component: SellerCommandDetails,
        beforeEnter: canManageSellerCommands,
      },
      {
        path: "acommandes/pourconfirmer/details/:id",
        name: "Commandes À Confirmer / Détails du commande",
        component: AdminCommandDetails,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "scommandes/pourconfirmer",
        name: "Commandes À Confirmer",
        component: PendingCommandsSeller,
        beforeEnter: canManageSellerCommands,
      },
      {
        path: "scommandes/refusees",
        name: "Commandes Non Confirmées",
        component: UnConfirmedSellerCommand,
        beforeEnter: canManageSellerCommands,
      },
      {
        path: "acommandes/refusees",
        name: "Commandes Non Confirmées",
        component: UnConfirmedAdminCommand,
        beforeEnter: canManageAdminCommands,
      },
      {
        path: "profile",
        name: "Admin Profile",
        component: AdminProfile,
        beforeEnter: canManageUsers,
      },
      {
        path: "sellermanagers",
        name: "Managers du magazin",
        component: SellerManagerList,
        beforeEnter: canManageSellersManagers,
      },
      {
        path: "sellermanagers/nouveau",
        name: "Managers du magazin / Nouveau",
        component: CreateSellerManager,
        beforeEnter: canManageSellersManagers,
      },
      {
        path: "sellermanagers/modification/:id",
        name: "Managers du magazin / Modification",
        component: UpdateSellerManager,
        beforeEnter: canManageSellersManagers,
      },
      {
        path: "utilisateurs/admins",
        name: "Utilisateurs / Administrateurs",
        component: AdminsList,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/admins/nouveau",
        name: "Utilisateurs / Administrateurs / Nouveau",
        component: CreateAdmin,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/admins/modification/:id",
        name: "Utilisateurs / Administrateurs / Modification",
        component: UpdateAdmin,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/vendeurs",
        name: "Utilisateurs / Vendeurs",
        component: SellersList,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/vendeurs/modification/:id",
        name: "Utilisateurs / Vendeurs / Modification ",
        component: UpdateSeller,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/vendeurs/nouveau",
        name: "Utilisateurs / Vendeurs / Nouveau",
        component: CreateNewSeller,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/acheteurs/nouveau",
        name: "Utilisateurs / Acheteurs / Nouveau",
        component: CreateNewBuyer,
        beforeEnter: canManageUsers,
      },
    
   {
        path: "utilisateurs/acheteurs/modification/:id",
        name: "Utilisateurs / Acheteurs / Modification ",
        component: UpdateBuyer,
        beforeEnter: canManageUsers,
      },
      {
        path: "utilisateurs/acheteurs",
        name: "Utilisateurs / Acheteurs",
        component: BuyersList,
        beforeEnter: canManageUsers,
      },
      {
        path: "marques/confirmees",
        name: "Marques / Confirmées",
        component: ConfirmedBrandsList,
      },
      {
        path: "marques/non-confirmees",
        name: "Marques / Non Confirmées",
        component: NonConfirmedBrandsList,
      },
      {
        path: "marques/pourconfirmer",
        name: "Marques / À Confirmer",
        component: PendingBrandsList,
      },
      {
        path: "marque/nouveau",
        name: "Marques / Nouveau",
        component: CreateBrand,
      },
      {
        path: "marque/modifier/:id",
        name: "Marques / Modification",
        component: UpdateBrand,
      },
      {
        path: "categories",
        name: "Catégories",
        component: CategoriesList,
        beforeEnter: canManageCategories,
      },
      {
        path: "categories/nouveau",
        name: "Catégories / Nouveau",
        component: CreateCategory,
        beforeEnter: canManageCategories,
      },
      {
        path: "categories/update/:id",
        name: "Catégories / Modification",
        component: UpdateCategory,
        beforeEnter: canManageCategories,
      },
      {
        path: "sous-categories",
        name: "Sous catégories",
        component: SubCategoriesList,
        beforeEnter: canManageSubCategories,
      },
      {
        path: "categories/:id/sous-categories",
        name: "Sous catégories par catégorie",
        component: SubCatListByCat,
        beforeEnter: canManageSubCategories,
      },
      {
        path: "sous-categories/nouveau",
        name: "Sous Catégories / Nouveau",
        component: CreateSubCategory,
        beforeEnter: canManageSubCategories,

      },
      {
        path: "subcategories/update/:id",
        name: "Sous-Catégories / Modification",
        component: UpdateSubCategory,
        beforeEnter: canManageSubCategories,

      },
      {
        path: "produits",
        name: "Produits ",
        component: SellerProducts,
        beforeEnter: canManageOwnProducts,

      },
      {
        path: "produits/non-confirmes",
        name: "Produits / Non Confirmés",
        component: UnconfirmedProducts,
        beforeEnter: canManageProducts,
      },
      {
        path: "produits/confirmes",
        name: "Produits / Confirmés",
        component: ConfirmedProducts,
        beforeEnter: canManageProducts,

      },
      {
        path: "produits/nouveau",
        name: "Produits / Nouveau",
        component: CreateProduct,
        beforeEnter: canManageProducts,

      },
      {
        path: "produits/nouveauprod",
        name: "Produits / Nouveau Produit",
        component: CreateSellerProduct,
        beforeEnter: canManageOwnProducts,

      },
      {
        path: "produit/update/:id",
        name: "Produits / Modification",
        component: UpdateProduct,

      },
      {
        path: "productpromotion",
        name: "Promotion des Produits",
        component: ProductsPromotion,
        beforeEnter: canManageProducts,

      },
      {
        path: "/demandes/inscription/vendeurs",
        name: "Demandes / Inscription / Vendeurs",
        component: InscrDemandsSellers,
        beforeEnter: canManageUsers,
      },
      {
        path: "/inscription/vendeur/:id",
        name: "Demandes / Inscription / Vendeur",
        component: CreateSeller,
        beforeEnter: canManageUsers,
      },
      {
        path: "/demandes/inscription/acheteurs",
        name: "Demandes / Inscription / Acheteurs",
        component: InscrDemandsBuyers,
        beforeEnter: canManageUsers,
      },
      {
        path: "/inscription/acheteur/:id",
        name: "Demandes / Inscription / Acheteur",
        component: CreateBuyer,
        beforeEnter: canManageUsers,
      },
      {
        path: "/banners",
        name: "Banners",
        component: Banners,
        beforeEnter: canManageBunners,
      },

      {
        path: "typography",
        name: "Typography",
        component: Typography,
      },
      {
        path: "icons",
        name: "Icons",
        component: Icons,
      },
      {
        path: "maps",
        name: "Maps",
        meta: {
          hideFooter: true,
        },
        component: Maps,
      },
      {
        path: "notifications",
        name: "Notifications",
        component: Notifications,
      },
      {
        path: "upgrade",
        name: "Upgrade to PRO",
        component: UpgradeToPRO,
      },
    ],
  },
];

// export default routes;
