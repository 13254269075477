<style>
select[name="example_length"]{
  padding-right: 18px!important;
}
.dataTables_length{
  padding-bottom: 20px!important;
}
</style>
<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>

      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>

    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
        <div
            v-if="AnyErrors && AnyErrors.includes('La catégorie')"
            class="bg-red-200 border-red-600 text-red-600 border-l-4 p-4"
            role="alert"
        >
          <p class="font-bold">Erreur !</p>
          <p>
            {{ AnyErrors }}
          </p>
        </div>

        <div
            v-if="AnySuccess && AnySuccess.includes('La catégorie')"
            class="bg-green-200 border-green-600 text-green-600 border-l-4 p-4"
            role="alert"
        >
          <p class="font-bold">Succée !</p>
          <p>
            {{ AnySuccess }}
          </p>
        </div>
      
                <md-card>
                  <md-card-header data-background-color="orange">
                    <h4 class="title">Liste des marques à confirmer</h4>
                  </md-card-header>
                  <md-card-content>
                    <div >
                      <MdTable v-model="pendingbrands"  table-header-color="orange">
                        <md-table-row id="table" slot="md-table-row" slot-scope="{ item }">
                          <md-table-cell md-label="Nom de la marque ">{{ item.brand_name }}</md-table-cell>
                          <md-table-cell md-label="Image"><img style="height: 100px!important;width: 100px!important;" :src="item.image_url ? item.image_url : non_image"/></md-table-cell>
                          <md-table-cell md-label="Action">
                            <md-button @click="Confirm(item.id)" class="md-dense md-raised md-primary">Confirmer</md-button>

                            <md-button  @click="Refuse(item.id)" class="md-dense md-raised md-primary">
                            Refuser</md-button>

                          </md-table-cell>
                        </md-table-row>
                      </MdTable>

                    </div>
                  </md-card-content>
                </md-card>

      </div>


    </div>

  </div>
</template>

<script>
//import { SimpleTable, OrderedTable } from "@/components";
import { store } from "../../store/modules/GeneralStore.js";

import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"


export default {
  components: {
    //OrderedTable,
    //SimpleTable,
  },
  data() {
    return {
      currentBrand: null,
      non_image: require("@/assets/img/null.png"),
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
    pendingbrands() {
      return store.state.pendingbrands;
    },
  },
  mounted: function () {
    store.dispatch("getPendingBrands")
    let context = this;
    if (sessionStorage.getItem("brandSuccess")){
      context.$toasted.success(sessionStorage.getItem("brandSuccess"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("brandSuccess");
    }
    if (sessionStorage.getItem("brandError")){
      context.$toasted.error(sessionStorage.getItem("brandError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("brandError");
    }
    
  },
  methods: {
    Confirm(brand_id){
      console.log(brand_id);
      let payload  ={
          
          id : brand_id
        } 
      store.dispatch("ConfirmBrand", payload).then(
        function () {
                  window.location = "/marques/pourconfirmer";
          }  
      );

    },
    Refuse(brand_id){
      console.log(brand_id);
      
      let payload  ={
          
          id : brand_id
        } 
      store.dispatch("RefuseBrand", payload).then(
        function () {
                  window.location = "/marques/pourconfirmer";
                }
        );
      }

  },
};
</script>
