<template>
    <form class="p-5" @submit.prevent>
      <div class="space-y-12 p-5 bg-white">

  
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Utilisateur / Admin
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Les champs avec sign <span class="required">*</span> sont obligatoires !
          </p>
  
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                for="nom-name"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Nom <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="admin.name"
                  name="name"
                  id="name"
                  autocomplete="given-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3"> 
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Email <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="email"
                  v-model="admin.email"
                  name="email"
                  id="email"
                  autocomplete="email"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                for="email"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Mot de passe <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="password"
                  v-model="admin.password"
                  name="password"
                  id="password"
                  autocomplete="password"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Securité
          </h2>

          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                  for="nom-name"
                  class="block text-sm font-medium leading-6 text-gray-900"
              >Permissions <span class="required">*</span></label
              >
              <div class="mt-2">
                <div>
                  <multiselect v-model="value" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" track-by="code" :options="options" :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="mt-6 flex items-center justify-end gap-x-6">
      
        <router-link :to="{ name: 'Utilisateurs / Administrateurs' }">
          <md-button  
          style="background-color: #e0622a !important" >Annuler</md-button></router-link>
        <md-button
          :class="{ 'disabled:opacity-70': isLoading }"
          @click="create"
          type="submit"
          :disabled="isLoading"
          class="md-raised"
          style="background-color: #1476b5 !important"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              v-if="isLoading"
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
              ></path>
            </svg>
          </span>
          Modifier
        </md-button>
      </div>
    </form>
  </template>
  
  <script>
  // import { PhotoIcon, UserCircleIcon } from "@heroicons/vue/24/solid";
  
  import { store } from "@/store/modules/GeneralStore";
  import Multiselect from 'vue-multiselect'


  export default {
    components: {
      Multiselect,
      //   PhotoIcon,
      //   UserCircleIcon,
    },
    data() {
      return {
       admin_id : this.$route.params.id,
        value : [],
        options: [
          { name: 'Gestion des catégories', code: 'ManageCategories' },
          { name: 'gestion des sous catégories', code: 'ManageSubCategories' },
          { name: 'Gestion des produits', code: 'ManageProducts' },
          { name: 'Gestion des produits de promotion', code: 'ManagePromotedProducts' },
          { name: 'Création des marques', code: 'CreateBrands' },
          { name: 'Gestion des marques', code: 'ManageBrands' },
          { name: 'Gestion des statistiques', code: 'ManageStatistics' },
          // { name: 'Communication avec les clients', code: 'Chat' },
          { name: 'Gestion des communication', code: 'ManageChats' },
          { name: 'Gestion des reviews', code: 'ManageReviews' },
          // { name: 'Gestion des favoris', code: 'ManageFavorites' },
          { name: 'Gestion des commandes', code: 'ManageAdminCommands' },
          { name: 'Gestion des devis', code: 'ManageAdminDevis' },
          // { name: 'Demander devis', code: 'AskForDevis' },
          { name: 'Gestion des caractéristiques des produits', code: 'ManageCharacteristics'},
          // { name: 'Gestion des managers des vendeurs', code: 'ManageSellersManagers' },
          { name: 'Gestion des utilisateurs', code: 'ManageUsers' },
          { name: 'Gestion de paiement', code: 'ManagePaymentModes' },
          { name: 'Gestion des livraison', code: 'ManageDeliveryModes' },
          { name: 'Gestion des cartes', code: 'ManageCart' },
          { name: 'Gestion des roles', code: 'ManageRoles' },
          { name: 'Gestion des permissions', code: 'ManagePermissions' },
          { name: 'Gestion des bannières', code: 'ManageBunners' }
        ]
      };
    },
    computed: {
      isLoading() {
        return store.state.isLoading;
      },
      AnyErrors() {
        return store.state.error;
      },
      AnySuccess() {
        return store.state.success;
      },
      admin(){
        return store.state.admin;
      }
    },
  
    mounted: function () {
      let context = this;
       store.dispatch("getAdminById", this.admin_id).then(function () {
         let permissions = [];
         for(let i=0;i<store.state.admin.permissions.length;i++){
           for(let j=0;j<context.options.length;j++){
             if (store.state.admin.permissions[i].name===context.options[j].code){
               permissions.push({name : context.options[j].name, code :store.state.admin.permissions[i].name})
             }
           }
         }
         context.value= permissions;
       });
    },
    methods: {
      create() {
        let context = this;
        let permissions = [];
        for(let i=0;i<context.value.length;i++){
          permissions.push(context.value[i].code);
        }
        store.dispatch("updateAdmin", {
          nom: this.admin.name,
          email: this.admin.email,
          password: this.admin.password,
          admin_id: this.admin_id,
          permissions: permissions
        }).then(function (){
          if (sessionStorage.getItem("adminSuccess")){
            context.$toasted.success(sessionStorage.getItem("adminSuccess")+" : <a style='color : black;margin-left: 10px' href='/utilisateurs/admins'>consulter la liste des admins ici</a>",{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("adminSuccess");
          }
          if (sessionStorage.getItem("adminError")){
            context.$toasted.error(sessionStorage.getItem("adminError"),{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("adminError");
          }
        });
      },
    },
  };
  </script>
  <style >
  .required {
    font-weight: bolder;
    color :red;
    /* You can also add more styles here if needed */
  }
  </style>