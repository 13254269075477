<template>
  <div class="content">
    <div v-if="checkPermission(5)">
    <div v-if="this.totalStatistics===null" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>

      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
    </div>

    <div
        v-if="this.totalStatistics!==null"
         class="md-layout">
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card data-background-color="green">
          <template slot="header">
            <md-icon><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-coin" viewBox="0 0 16 16">
              <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
            </svg></md-icon>
          </template>

          <template slot="content">
            <p class="category">Total de Vente</p>
            <h2 class="title"> {{ totalStatistics.totalSales ?? 0}} DH </h2>
          </template>

          <!-- <template slot="footer">
            <div class="stats">
              <md-icon>date_range</md-icon>
              Last 24 Hours
            </div>
          </template> -->
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card  data-background-color="orange">
          <template slot="header">
            <md-icon>store</md-icon>
          </template>

          <template slot="content">
            <p class="category">Total de produits</p>
            <h2 class="title">
              {{ totalStatistics.totalProducts }}
              <!-- <small>GB</small> -->
            </h2>
          </template>

          <!-- <template slot="footer">
            <div class="stats">
              <md-icon class="text-danger">warning</md-icon>
              <a href="#pablo">Get More Space...</a>
            </div>
          </template> -->
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card data-background-color="red">
          <template slot="header">
            <md-icon>person</md-icon>
          </template>

          <template slot="content">
            <p class="category">Total des vendeurs </p>
            <h3 class="title"> {{ totalStatistics.totalSellers }} </h3>
          </template>

          <!-- <template slot="footer">
            <div class="stats">
              <md-icon>local_offer</md-icon>
              Tracked from Github
            </div>
          </template> -->
        </stats-card>
      </div>
      <div
        class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
      >
        <stats-card data-background-color="blue">
          <template slot="header">
            <md-icon>person</md-icon>
          </template>

          <template slot="content">
            <p class="category">Total des acheteurs</p>
            <h3 class="title"> {{ totalStatistics.totalBuyers }} </h3>
          </template>

          <!-- <template slot="footer">
            <div class="stats">
              <md-icon>update</md-icon>
              Just Updated
            </div>
          </template> -->
        </stats-card>
      </div>

      <div
        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
      >
<!--        <chart-card-->
<!--          :chart-data="dataCompletedTasksChart.data"-->
<!--          :chart-options="dataCompletedTasksChart.options"-->
<!--          :chart-type="'Line'"-->
<!--          data-background-color="green"-->
<!--        >-->
<!--          <template slot="content">-->
<!--            <h4 class="title">Completed Tasks</h4>-->
<!--            <p class="category">Last Campaign Performance</p>-->
<!--          </template>-->

<!--          <template slot="footer">-->
<!--            <div class="stats">-->
<!--              <md-icon>access_time</md-icon>-->
<!--              campaign sent 26 minutes ago-->
<!--            </div>-->
<!--          </template>-->
<!--        </chart-card>-->
      </div>

      <!--      <div-->
      <!--        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"-->
      <!--      >-->
      <!--        <md-card>-->
      <!--          <md-card-header data-background-color="orange">-->
      <!--            <h4 class="title">Employees Stats</h4>-->
      <!--            <p class="category">New employees on 15th September, 2016</p>-->
      <!--          </md-card-header>-->
      <!--          <md-card-content>-->
      <!--            <ordered-table table-header-color="orange"></ordered-table>-->
      <!--          </md-card-content>-->
      <!--        </md-card>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"-->
      <!--      >-->
      <!--        <nav-tabs-card>-->
      <!--          <template slot="content">-->
      <!--            <span class="md-nav-tabs-title">Tasks:</span>-->
      <!--            <md-tabs class="md-success" md-alignment="left">-->
      <!--              <md-tab id="tab-home" md-label="Bugs" md-icon="bug_report">-->
      <!--                <nav-tabs-table></nav-tabs-table>-->
      <!--              </md-tab>-->

      <!--              <md-tab id="tab-pages" md-label="Website" md-icon="code">-->
      <!--                <nav-tabs-table></nav-tabs-table>-->
      <!--              </md-tab>-->

      <!--              <md-tab id="tab-posts" md-label="server" md-icon="cloud">-->
      <!--                <nav-tabs-table></nav-tabs-table>-->
      <!--              </md-tab>-->
      <!--            </md-tabs>-->
      <!--          </template>-->
      <!--        </nav-tabs-card>-->
      <!--      </div>-->
    </div>
    <div  v-if="this.totalStatistics!==null" class=" rounded-xl h-auto shadow-md  ">
      <div class="md:flex " >
        <div class="md:shrink-0   bg-white lg:w-6/12">
          <Bar
              class="p-2"
              id="my-chart-id"
              :options="barChartAdmin.chartOptions"
              :data="barChartAdmin.chartData"
          />
        </div>
        <div class="ml-2 md:shrink-0 lg:w-6/12" style="height: 310px!important;background-color: white!important">
          <Pie
              class="mx-auto"
              :options="pieChartAdmin.chartOptions"
              :data="pieChartAdmin.chartData"
          /> </div>
      </div>
    </div>
    </div>
    <div v-if="checkPermission(6)">
      <div v-if="this.totalOwnStatistics===null" class="flex flex-col flex-1 gap-5 sm:p-2">
        <div class="flex flex-col flex-1 gap-3">
          <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        </div>

        <div class="flex flex-col flex-1 gap-3">
          <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
          <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        </div>
      </div>
      <div
          v-if="this.totalOwnStatistics!==null"
          class="md-layout">
        <div
            class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="green">
            <template slot="header">
              <md-icon><svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" class="bi bi-coin" viewBox="0 0 16 16">
                <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"/>
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"/>
              </svg></md-icon>
            </template>

            <template slot="content">
              <p class="category">Total de Vente</p>
              <h2 class="title"> {{ totalOwnStatistics.totalSales ?? 0}} DH </h2>
            </template>

            <!-- <template slot="footer">
              <div class="stats">
                <md-icon>date_range</md-icon>
                Last 24 Hours
              </div>
            </template> -->
          </stats-card>
        </div>
        <div
            class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card  data-background-color="orange">
            <template slot="header">
              <md-icon>store</md-icon>
            </template>

            <template slot="content">
              <p class="category">Total de produits</p>
              <h2 class="title">
                {{ totalOwnStatistics.totalProducts }}
                <!-- <small>GB</small> -->
              </h2>
            </template>

            <!-- <template slot="footer">
              <div class="stats">
                <md-icon class="text-danger">warning</md-icon>
                <a href="#pablo">Get More Space...</a>
              </div>
            </template> -->
          </stats-card>
        </div>
        <div
            class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="red">
            <template slot="header">
              <md-icon>person</md-icon>
            </template>

            <template slot="content">
              <p class="category">Total des gérants </p>
              <h3 class="title"> {{ totalOwnStatistics.totalSellersManagers }} </h3>
            </template>

            <!-- <template slot="footer">
              <div class="stats">
                <md-icon>local_offer</md-icon>
                Tracked from Github
              </div>
            </template> -->
          </stats-card>
        </div>
        <div
            class="md-layout-item md-medium-size-50 md-xsmall-size-100 md-size-25"
        >
          <stats-card data-background-color="blue">
            <template slot="header">
              <md-icon>store</md-icon>
            </template>

            <template slot="content" style="padding-left: -100px!important;">
              <p class="category">Commands a confirme</p>
              <h3 class="title"> {{ totalOwnStatistics.totalCommandsToConfirm }} </h3>
            </template>

            <!-- <template slot="footer">
              <div class="stats">
                <md-icon>update</md-icon>
                Just Updated
              </div>
            </template> -->
          </stats-card>
        </div>

        <div
            class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <!--        <chart-card-->
          <!--          :chart-data="dataCompletedTasksChart.data"-->
          <!--          :chart-options="dataCompletedTasksChart.options"-->
          <!--          :chart-type="'Line'"-->
          <!--          data-background-color="green"-->
          <!--        >-->
          <!--          <template slot="content">-->
          <!--            <h4 class="title">Completed Tasks</h4>-->
          <!--            <p class="category">Last Campaign Performance</p>-->
          <!--          </template>-->

          <!--          <template slot="footer">-->
          <!--            <div class="stats">-->
          <!--              <md-icon>access_time</md-icon>-->
          <!--              campaign sent 26 minutes ago-->
          <!--            </div>-->
          <!--          </template>-->
          <!--        </chart-card>-->
        </div>

        <!--      <div-->
        <!--        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"-->
        <!--      >-->
        <!--        <md-card>-->
        <!--          <md-card-header data-background-color="orange">-->
        <!--            <h4 class="title">Employees Stats</h4>-->
        <!--            <p class="category">New employees on 15th September, 2016</p>-->
        <!--          </md-card-header>-->
        <!--          <md-card-content>-->
        <!--            <ordered-table table-header-color="orange"></ordered-table>-->
        <!--          </md-card-content>-->
        <!--        </md-card>-->
        <!--      </div>-->
        <!--      <div-->
        <!--        class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"-->
        <!--      >-->
        <!--        <nav-tabs-card>-->
        <!--          <template slot="content">-->
        <!--            <span class="md-nav-tabs-title">Tasks:</span>-->
        <!--            <md-tabs class="md-success" md-alignment="left">-->
        <!--              <md-tab id="tab-home" md-label="Bugs" md-icon="bug_report">-->
        <!--                <nav-tabs-table></nav-tabs-table>-->
        <!--              </md-tab>-->

        <!--              <md-tab id="tab-pages" md-label="Website" md-icon="code">-->
        <!--                <nav-tabs-table></nav-tabs-table>-->
        <!--              </md-tab>-->

        <!--              <md-tab id="tab-posts" md-label="server" md-icon="cloud">-->
        <!--                <nav-tabs-table></nav-tabs-table>-->
        <!--              </md-tab>-->
        <!--            </md-tabs>-->
        <!--          </template>-->
        <!--        </nav-tabs-card>-->
        <!--      </div>-->
      </div>

      <div  v-if="this.totalOwnStatistics!==null" class=" rounded-xl h-auto shadow-md  ">
        <div class="md:flex " >
          <div class="md:shrink-0   bg-white lg:w-6/12">
            <Bar
                class="p-2"
                id="my-chart-id"
                :options="barChartSeller.chartOptions"
                :data="barChartSeller.chartData"
            />
          </div>
          <div class="ml-2 md:shrink-0 lg:w-6/12" style="height: 310px!important;background-color: white!important">
            <Pie
                class="mx-auto"
                :options="pieChartSeller.chartOptions"
                :data="pieChartSeller.chartData"
            /> </div>
        </div>
      </div>


    </div>

  </div>


</template>

<script>
  import { store } from "@/store/modules/GeneralStore";
  import { Bar } from 'vue-chartjs'
  import { Pie } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement } from 'chart.js'

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale,ArcElement)
import {
  StatsCard,
  ChartCard,
  NavTabsCard,
  NavTabsTable, 
  OrderedTable,
} from "@/components";

  export default {

  components: {
    StatsCard,
    ChartCard,
    Bar,
    Pie
    // NavTabsCard,
    //NavTabsTable,
    //OrderedTable,
  },
    data() {
      return {
        barChartAdmin : {
          chartData: {
            labels: [ 'Jan', 'Fev', 'Mar','Avr','Mai','Juin','Juil','Aout','Sep','Oct','Nov','Dec' ],
            datasets: [
                { label : "Revenue Par Mois ",
                  data: [0,0,0,0,0,0,0,0,0,0,0,0],
                  backgroundColor: '#fd7f2d',
                }
            ]
          },
          chartOptions: {
            responsive: true
          }
        },
        pieChartAdmin : {
          chartData: {
            labels: [ 'Livrée', 'Non Livrée', 'Retourner'],
            datasets: [
                { label : "Commands Tracking",
                  data: [0,0,0],
                  backgroundColor: ['#fd7f2d','#41B883', '#E46651'],
                }
            ]
          },
          chartOptions: {
            responsive: true
          }
        },
        barChartSeller : {
          chartData: {
            labels: [ 'Jan', 'Fev', 'Mar','Avr','Mai','Juin','Juil','Aout','Sep','Oct','Nov','Dec' ],
            datasets: [
              { label : "Revenue Par Mois ",
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: '#fd7f2d',
              }
            ]
          },
          chartOptions: {
            responsive: true
          }
        },
        pieChartSeller : {
          chartData: {
            labels: [ 'Livrée', 'Non Livrée', 'Retourner'],
            datasets: [
              { label : "Commands Tracking",
                data: [0,0,0],
                backgroundColor: ['#fd7f2d','#41B883', '#E46651'],
              }
            ]
          },
          chartOptions: {
            responsive: true
          }
        }
      }
    },

  mounted: function (){
    let context= this
    if (this.checkPermission(5)){
      store.dispatch("getTotalStatistics");
      store.dispatch("getCountCommandsPerDeliveryMode").then(function () {
        let response = context.countCommandsPerDeliveryMode;
        let delivred_count = 0;
        let not_delivred_count = 0;
        let returned_count  = 0;
        for (let j=0; j<response.length; j++){
          if (response[j].delivery==="delivered"){
            delivred_count = response[j].total;
          }
          else if (response[j].delivery==="not_delivered"){
            not_delivred_count = response[j].total;
          }
          else{
            returned_count = response[j].total;
          }
        }
        context.pieChartAdmin = {
          chartData: {
            labels: [ 'Livrée', 'Non Livrée', 'Retourner'],
            datasets: [
              { label : "Commands Tracking",
                data: [delivred_count,not_delivred_count,returned_count],
                backgroundColor: ['#fd7f2d','#41B883', '#E46651'],
              }
            ]
          },
          chartOptions: {
            responsive: true
          }
        };

      });
      store.dispatch("getTotalSalesPerMonth")
          .then(function (){
                let response = context.totalsalesperMonth;
                let newValues = [];
                for (let i=1; i<= 12  ; i++){
                  let value= 0;
                  for (let j=0; j<response.length; j++){

                    if (i== response[j].month){
                      value= response[j].total_sales
                    }
                  }
                  newValues.push(value)
                }
                context.barChartAdmin = {
                  chartData: {
                    labels: [ 'Jan', 'Fev', 'Mar','Avr','Mai','Juin','Juil','Aout','Sep','Oct','Nov','Dec' ],
                    datasets: [
                      { label : "Revenue Par Mois ",
                        data: newValues,
                        backgroundColor: '#fd7f2d',
                      }
                    ]
                  },
                  chartOptions: {
                    responsive: true
                  }
                };

              }
          );
    }
    else if (this.checkPermission(6)){
      store.dispatch("getTotalOwnStatistics");
      store.dispatch("getCountSellerCommandsPerDeliveryMode").then(function () {
        let response = context.countSellerCommandsPerDeliveryMode;
        console.log("data",response);
        let delivred_count = 0;
        let not_delivred_count = 0;
        let returned_count  = 0;
        for (let j=0; j<response.length; j++){
          if (response[j].delivery==="delivered"){
            delivred_count = response[j].total;
          }
          else if (response[j].delivery==="not_delivered"){
            not_delivred_count = response[j].total;
          }
          else{
            returned_count = response[j].total;
          }
        }
        context.pieChartSeller = {
          chartData: {
            labels: [ 'Livrée', 'Non Livrée', 'Retourner'],
            datasets: [
              { label : "Commands Tracking",
                data: [delivred_count,not_delivred_count,returned_count],
                backgroundColor: ['#fd7f2d','#41B883', '#E46651'],
              }
            ]
          },
          chartOptions: {
            responsive: true
          }
        };

      });

      store.dispatch("getTotalSellerSalesPerMonth").then(function () {
            let response = context.totalsellersalesperMonth;
            let newValues = [];
            for (let i=1; i<= 12  ; i++){
              let value= 0;
              for (let j=0; j<response.length; j++){

                if (i== response[j].month){
                  value= response[j].total_sales
                }
              }
              newValues.push(value)
            }
            console.log(newValues);
            context.barChartSeller = {
              chartData: {
                labels: [ 'Jan', 'Fev', 'Mar','Avr','Mai','Juin','Juil','Aout','Sep','Oct','Nov','Dec' ],
                datasets: [
                  { label : "Revenue Par Mois ",
                    data: newValues,
                    backgroundColor: '#fd7f2d',
                  }
                ]
              },
              chartOptions: {
                responsive: true
              }
            };

          }
      );

    }




  },
  computed:{
    isLoading(){
      return store.state.isLoading;
    },
    totalStatistics(){
      return store.state.totalStatistics;
    },
    totalsalesperMonth(){
      return store.state.salesperMonth;
    },
    totalsellersalesperMonth(){
      return store.state.salesperMonth;
    },
    countCommandsPerDeliveryMode(){
      return store.state.countCommandsPerDeliveryMode;
    },
    countSellerCommandsPerDeliveryMode(){
      return store.state.countSellerCommandsPerDeliveryMode;
    },
    totalOwnStatistics(){
      return store.state.totalOwnStatistics;
    },
  
  },
  methods: {
    checkPermission(per_id){
      let can = false;
      for (let i=0;i<store.state.user.permissions.length;i++){
        if (store.state.user.permissions[i].id===per_id){
          can = true;
        }
      }
      return can;
    },
  },
};
</script>
<style >
.custom-height {
  height: 500px; /* Set the height to 300 pixels */
}
</style>