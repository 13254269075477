<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>



    </div>
    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
    


        <md-card >
          <md-card-header 
          style="background-color: #e0622a !important">
            <h4 class="title">Liste des demandes de devis</h4>
          </md-card-header>

          <md-card-content>
            <!--            <simple-table :tableData="categories" ></simple-table>-->
            <div>
              <md-table  v-model="admindevis" table-header-color="orange">
                <md-table-row slot="md-table-row" slot-scope="{ item }" v-for="item in admindevis" :key="item.id">
                  <md-table-cell class="text-center" md-label="">
                    <input 
                    class="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    type="checkbox" v-model="selectedRows" :value="item.id"></md-table-cell>
                  <md-table-cell class="text-center" md-label="Acheteur">{{
                      item.buyer.user.name
                    }} </md-table-cell>
                     <md-table-cell class="text-center" md-label="Numéro de téléphone"> +212 {{
                      item.buyer.phone_number
                    }}</md-table-cell>
                     
                  <md-table-cell class="text-center" md-label="Nom du produit">{{
                      item.product_without_price.product.name_product
                    }}</md-table-cell>
                      <md-table-cell class="text-center" md-label="">
                        <button class="btn" @click="showProduct(item)"><svg
                         style="cursor: pointer; margin-right: 12%;"
                          xmlns="http://www.w3.org/2000/svg" 
                          width="16" height="16" fill="currentColor"
                           class="bi bi-eye-fill" viewBox="0 0 16 16">
                         <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                         <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                        
                       </svg></button>
                  </md-table-cell>

                 
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>
         <div >
      <router-link v-if="admindevis.length>0" to= "/acommandes/confirmees/nouveau" >
          <md-button 
          @click="navigateToAnotherPage()"
              class="md-raised"
              style="background-color: #1476b5 !important"
          >Passer une commande</md-button
          >
        </router-link>
      </div>
      </div>
</div>

    <div
        class="relative z-10 bg-gray-100"
        style="z-index: 50000"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        :hidden="!DetailsModel"
    >
      <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      ></div>
    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <div
            class="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
        >
          <div
              class="flex items-center cursor-pointer justify-end "
          >
            <svg  @click="showProduct(null)" class="bi bi-x-lg  m-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  viewBox="0 0 16 16">
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
            </svg>
          </div>

          <div v-if="produit!==null"  class="bg-white" >
            <div class="mx-auto max-w-2xl px-4 py-2 sm:px-6 sm:py-3 lg:max-w-7xl lg:px-8">
              <h3
                  class="text-base font-semibold leading-6 text-gray-900 py-3"
                  id="modal-title"
              >
                Produit Demandée : {{produit.name_product}}
              </h3>
              <div class="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 xl:gap-x-8">
                <a  class="group">
                  <div class="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                    <img style="max-height:300px!important;"  :src="produit.media[0]?produit.media[0].original_url:non_image" alt="test" class="w-full w-100 object-cover object-center group-hover:opacity-75" />
                  </div>
                  <div class="mx-auto text-center grid max-w-4xl grid-cols-12  mt-4 p-4">
                    <div class="col-span-12 sm:col-span-6 lg:border-r-4 border-black ">
                      <h3 class=" text-sm text-gray-700 "> Réference product : <br> {{produit.reference_product}}</h3>
                      <h3 class=" text-sm text-gray-700 border-t-4 border-black mt-4 pt-4"> Réference tawdaw : <br> {{produit.reference_tawdaw}}</h3>
                    </div>
                    <div class="col-span-12 sm:col-span-6">
                      <h3 class=" text-sm text-gray-700"> Quantité de stock : <br> {{produit.qte_stock}}</h3>
                      <h3 class=" text-sm text-gray-700 border-t-4 border-black mt-4 pt-4"> Quantité min de vente : <br> {{produit.qte_min_vente??1}}</h3>                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div v-else class="flex flex-col flex-1 gap-5 sm:p-2">
            <div class="flex flex-col flex-1 gap-3">
              <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
            </div>
            <div class="flex flex-col flex-1 gap-3">
              <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
              <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
            </div>



          </div>
        </div>
      </div>
    </div>
    </div>

    </div>


</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import DataTable from 'datatables.net-dt';
import ShowDetails from "@/pages//details/ShowDetails.vue"
import BuyerDetails from "@/pages//details/BuyerDetails.vue"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
import BuyerDetailsVue from '../details/BuyerDetails.vue';
export default {
  components: {
   
  },
  data() {
    return {
      // DeleteModalOn: false,
      // currentAdmin: null,
      non_image: require("@/assets/img/null.png"),
      selectedRows: [],
      DetailsModel : false,
      produit : null,
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    admindevis() {
      return store.state.admindevis;
    },
  },
  mounted: function () {
   let context= this;
    store.dispatch("getAdminDevis", store.state.user.id).then(
        function () {
          console.log('salam',context.admindevis)
        }
    );
   
  },
  methods: {
    navigateToAnotherPage() {
    // Get the selected row IDs from the selectedRows array
    const selectedRowIds = this.selectedRows;

    localStorage.setItem('selectedRowIds', JSON.stringify(selectedRowIds));
  },
 
    showProduct(data) {
      if (data!==null){
        this.DetailsModel = true;
      }
      else {
        this.DetailsModel = false;
      }
      this.produit = data.product_without_price.product;
      console.log("product",this.produit);
      console.log("allDevis",this.admindevis);
      console.log("oneDevis",data);
    },
   
  } 
};
</script>

