import { render, staticRenderFns } from "./CreateProduct.vue?vue&type=template&id=bbe3cabc"
import script from "./CreateProduct.vue?vue&type=script&lang=js"
export * from "./CreateProduct.vue?vue&type=script&lang=js"
import style0 from "./CreateProduct.vue?vue&type=style&index=0&id=bbe3cabc&prod&lang=css"
import style1 from "./CreateProduct.vue?vue&type=style&index=1&id=bbe3cabc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports