<style>
.hidden_input{
  display: none!important;
}
</style>
<template>
  <div v-if="isLoading && this.chara.length===0" class="flex flex-col flex-1 gap-5 sm:p-2">
    <div class="flex flex-col flex-1 gap-3">
      <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
    </div>

    <div class="flex flex-col flex-1 gap-3">
      <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
    </div>
  </div>
  <form v-else class="p-5" @submit.prevent>
      <div class="space-y-12 p-5 bg-white">
<!--        <div-->
<!--          v-if="AnyErrors"-->
<!--          class="bg-red-200 border-red-600 text-red-600 border-l-4 p-4"-->
<!--          role="alert"-->
<!--        >-->
<!--          <p class="font-bold">Erreur !</p>-->
<!--          <p>-->
<!--            {{ AnyErrors }}-->
<!--          </p>-->
<!--        </div>-->
  
<!--        <div-->
<!--          v-if="AnySuccess"-->
<!--          class="bg-green-200 border-green-600 text-green-600 border-l-4 p-4"-->
<!--          role="alert"-->
<!--        >-->
<!--          <p class="font-bold">Succée !</p>-->
<!--          <p>-->
<!--            {{ AnySuccess }}-->
<!--            <router-link :to="{ name: 'Produits / Confirmés' }">-->
<!--              consulter la liste des produits confirmés ici</router-link>-->
<!--          </p>-->
<!--        </div>-->
  
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
           Produits
          </h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">
            Les champs avec la signe <span class="required">*</span> sont obligatoires !
          </p>
  
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                for="reference_tawdaw"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Référence de TAWDAW <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="product.reference_tawdaw"
                  name="reference_tawdaw"
                  id="reference_tawdaw"
                  autocomplete="given-name"
                  readonly
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                for="reference_product"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Référence du produit <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="product.reference_product"
                  name="reference_product"
                  id="reference_product"
                  autocomplete="reference_product"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                for="name_product"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Nom du produit <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="product.name_product"
                  name="name_product"
                  id="name_product"
                  autocomplete="password"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                for="description"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Description <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="product.description"
                  name="description"
                  id="description"
                  autocomplete="company"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
        


           
              <div class="sm:col-span-3">
            <label
              for="sub_category"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Sous-Catégorie <span class="required">*</span></label
            >
            <div class="mt-2">
              <select v-model="product.sub_category_id"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
      <optgroup v-for="category in categories" :label="'Catégorie - ' + category.name_cat_fr" :key="category.id">
        <option v-for="subCategory in category.subcategories" :value="subCategory.id" :key="subCategory.id">{{ subCategory.name_scat_fr }}</option>
      </optgroup>
    </select>
                <!-- <option
                  v-for="item in categories"
                  :value="item.id"
                  :key="item.id"
                >
                  {{ item.name_scat_fr }}
                </option> -->
              
            </div>
          </div>



            <div class="sm:col-span-3">
              <label
                for="qte_stock"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Quantité du Stock <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="product.qte_stock"
                  name="qte_stock"
                  id="qte_stock"
                  autocomplete="qte_stock"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
            <div class="sm:col-span-3">
              <label
                for="qte_min_vente"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Quantité de vente minimale <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="product.qte_min_vente"
                  name="qte_min_vente"
                  id="qte_min_vente"
                  autocomplete="qte_min_vente"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

          <div class="sm:col-span-3">
                <label
                  for="type_price"
                  class="block text-sm font-medium leading-6 text-gray-900"
                  >Type du Produit <span class="required">*</span></label
                >
                <div class="mt-2">
                  <select
                    v-model="product.type_price"
                    name="type_price"
                    id="type_price"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  > 
                        <option value="priced" selected>avec prix</option>
                        <option value="not_priced">sans prix</option>
                  </select>
                </div>
              </div>
<!--              <div class="sm:col-span-3">-->
<!--            <label-->
<!--              for="brand"-->
<!--              class="block text-sm font-medium leading-6 text-gray-900"-->
<!--              >Marque <span class="required">*</span></label-->
<!--            >-->
<!--            <div class="mt-2">-->
<!--              <select-->
<!--                v-model="product.brand_id"-->
<!--                name="brand_id"-->
<!--                id="brandy_id"-->
<!--                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
<!--              >-->
<!--                <option-->
<!--                  v-for="item in getBrands"-->
<!--                  :value="item.id"-->
<!--                  :key="item.id"-->
<!--                >-->
<!--                  {{ item.brand_name }}-->
<!--                </option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
 <div class="sm:col-span-3">
            <label
             v-if="product.type_price === 'priced' "
              for="reel_price"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Prix Aprés réduction<span class="required">*</span></label
            >
              <div class="mt-2">
                <input
                  type="text"
                  v-if="product.type_price === 'priced'"
                  v-model="product.reel_price"
                  name="reel_price"
                  id="reel_price"
                  autocomplete="company"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
          </div>

        <div class="sm:col-span-3">
            <label
             v-if="product.type_price === 'priced' "
              for="reel_price"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Prix Avant réduction</label>
              <div class="mt-2">
                <input
                  type="text"
                  v-if="product.type_price === 'priced'"
                  v-model="product.virtual_price"
                  name="virtual_price"
                  id="virtual_price"
                  autocomplete="virtual_price"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
          </div>
            <div class="sm:col-span-3">
            <div class="flex justify-center items-center mt-10 ">
              <div class="w-auto" id="my-strictly-unique-vue-upload-multiple-image " >
                <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    @edit-image="editImage"
                    :data-images="images"
                    dragText="Sélectionner les images de produit"
                    browseText="Naviguer"
                    primaryText="Principale"
                    markIsPrimaryText="Definir comme principale"
                    popupText="pop"
                    dropText="drop"
                    idUpload="myIdUpload"
                    editUpload="myIdEdit"
                ></vue-upload-multiple-image>
              </div>
            </div>
            </div>

            <div>
            <div>
              <div class="mb-3">
                <div class="relative inline-block w-10 mr-2 align-middle select-none">
                  <input :checked="hasBrand" @change="changeBrandState()" type="checkbox" name="toggle" id="brand" class="checked:bg-gray-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                  <label for="Gray" class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer">
                  </label>
                </div>
                <span class="font-medium text-gray-400">Brand</span>
              </div>
              <div class="sm:col-span-3 mb-3">
               <div class="mt-2" :hidden="!this.hasBrand">
                <select v-model="product.brand_id"
                name="brand_id"
                        id="brandy_id"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in getBrands"
                :value="item.id"
                :key="item.id">
               {{ item.brand_name }}
                </option>
                </select>
                </div>
              </div>
            </div>


            <div v-for="(item, index) in chara" :key="index">
              <div class="mb-3">
                <div class="relative inline-block w-10 mr-2 align-middle select-none">
                  <input type="checkbox" name="toggle" @change="checkboxEventListener(item.id)" :id="'checkbox'+item.id"  class="check_btn checked:bg-gray-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"/>
                  <label for="Gray" class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer">
                  </label>
                </div>
                <span class="font-medium text-gray-400">
                  {{item.name_fr}}
                </span>
              </div>
              <div class="sm:col-span-3 mb-3" >
                <div class="mt-2">
                  <input
                      type="text"
                      :id="item.id"
                      autocomplete="virtual_price"
                      class="hidden_input chara_inputs block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>


            <!-- Loop through the data array using v-for -->
<!--    <div v-for="(item, index) in chara" :key="index">-->
<!--      &lt;!&ndash; Create a label for each item &ndash;&gt;-->
<!--      <label :for="`input_${index}`" class="block text-sm font-medium leading-6 text-gray-900">-->
<!--        {{ item.name_fr }}-->
<!--      </label>-->


<!--      <div class="mt-2">-->
<!--             -->
<!--                <input-->
<!--        :id="`input_${index}`"-->
<!--        type="text"-->
<!--        v-model="product.characteristics[index].value_fr"-->
<!--        :name="`input_${index}`"-->
<!--        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"-->
<!--      />-->
<!--              </div>-->
<!--   -->
<!--    </div>-->
  </div>
            </div>
          </div>
        </div>
  
        
      
      <div class="mt-6 flex items-center justify-end gap-x-6">
        <router-link :to="{ name: 'Produits ' }">
          <md-button 
                    style="background-color: #e0622a !important"

          >Annuler</md-button></router-link
        >
        <!-- <a href="/categories" type="button" class="text-sm font-semibold leading-6 text-gray-900">Annuler</a> -->
        <md-button
          :class="{ 'disabled:opacity-70': isLoading }"
          @click="create"
          type="submit"
        
          class="md-raised"
          style="background-color: #1476b5 !important"         >
                  
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
       
              <path
                d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
              ></path>
          
          </span>
          Créer
        </md-button>
      </div>

  </form>

</template>
  
  <script>
  import { store } from "@/store/modules/GeneralStore";
  import $ from "jquery";
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  import ToastNotification from '../../components/ToastNotification';


  export default {
    components : {
      VueUploadMultipleImage,
      ToastNotification
    },
    data() {
      return {
        formData : null,
        images: [],
        product: {
          reference_tawdaw: null,
          reference_product: null,
          name_product: null,
          description: null,
          type_price: 'priced',
          qte_stock: null,
          qte_min_vente: null,
          virtual_price:null, 
          reel_price: null,
          brand_id:null,
          selectedMedia: null,
          sub_category_id: null,
          characteristics: [],
        },
        hasBrand : false,
      };
    },
  
   
  // watch: {
  //   // Watch for changes in the chara array, and update product.characteristics accordingly
  //   chara: {
  //     handler(newChara) {
  //       this.product.characteristics = newChara.map((item) => ({
  //         characteristic_id: item.id,
  //         value_fr: null,
  //       }));
  //     },
  //     immediate: true, // Call the handler immediately when the component is created
  //   },
  // },

    computed: {

      /*initializedCharacteristics() {
      return this.chara.map((item) => ({
        characteristic_id: item.id,
        value_fr: null,
      }));
    },*/
      isLoading() {
        return store.state.isLoading;
      },
      AnyErrors() {
        return store.state.error;
      },
      AnySuccess() {
        return store.state.success;
      },
      getBrands(){
          return store.state.confirmedbrands;
      },
      categories(){
          return store.state.categories;
      },
      user(){
          return store.state.user;
      },
      chara(){
          return store.state.characteristics;
      },

      
    },
    mounted: function () {

      let context = this;
      store.dispatch("getCharacteristics");
      store.dispatch("getBrands").then(function () {
        console.log("brands",context.getBrands);
      });
      store.dispatch("getCategoriesWithSubCategories");
      this.product.reference_tawdaw = "TAWDAW-"+Math.floor(100000 + Math.random() * 900000);
      //this.checkboxEventListener();
    },
    methods: {
      changeBrandState(){
        this.hasBrand = !this.hasBrand;
      },
      create() {
        let secondary_images = [];
        let primary_image = null;
        if (this.images.length>0){
          primary_image = this.images[0].path;
          if (this.images.length>1){
            for (let i=1;i<this.images.length;i++){
              secondary_images.push(this.images[i].path);
            }
          }
        }

        if (!this.hasBrand){
          this.product.brand_id = null;
        }
        let characteristics = [];
        let chara_inputs = $('.chara_inputs');

        for (let i=0;i<chara_inputs.length;i++){
          if (chara_inputs[i].value!==""){
            let chara = {
              id: chara_inputs[i].getAttribute('id'),
              value : chara_inputs[i].value,
            }
            characteristics.push(chara);
          }
        }

        // console.log(primary_image);
        // console.log(secondary_images);
        // console.log(characteristics);
        // console.log(this.product.brand_id);

        let payload = {
          reference_tawdaw: this.product.reference_tawdaw,
          reference_product: this.product.reference_product,
          name_product: this.product.name_product,
          description: this.product.description,
          type_price: this.product.type_price,
          qte_stock: this.product.qte_stock,
          qte_min_vente: this.product.qte_min_vente,
          creator_id: store.state.user.id,
          brand_id : this.product.brand_id,
          sub_category_id : this.product.sub_category_id,
          image_principal_url: primary_image,
          secondary_images_url : secondary_images,
          productCharacteristics: characteristics,
          virtual_price:this.product.virtual_price,
          reel_price: this.product.reel_price,

        };

        let context = this;
        store.dispatch("createSellerProduct",payload).then(function (){
          if (sessionStorage.getItem("productSuccess")){
            context.$toasted.success(sessionStorage.getItem("productSuccess")+" : <a style='color : black;margin-left: 10px' href='/produits'>consulter la liste des produits ici</a>",{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("productSuccess");
            context.product = {
              reference_tawdaw: null,
                  reference_product: null,
                  name_product: null,
                  description: null,
                  type_price: 'priced',
                  qte_stock: null,
                  qte_min_vente: null,
                  virtual_price:null,
                  reel_price: null,
                  brand_id:null,
                  selectedMedia: null,
                  sub_category_id: null,
                  characteristics: [],
            };
             context.hasBrand = false;
             context.images = [];
            $('#brand').attr("checked", false);
            for (let i=0;i<context.chara.length;i++){
               $('#checkbox'+context.chara[i].id).prop("checked", false);
               $('#'+context.chara[i].id)[0].classList.add("hidden_input");
               $('#'+context.chara[i].id)[0].value = "";
             }
          }
          if (sessionStorage.getItem("productError")){
            context.$toasted.error(sessionStorage.getItem("productError"),{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("productError");
          }
        });


        console.log(payload);
      },
      checkboxEventListener(id){
       let input = $('#'+id)[0];
       if (input.classList.contains("hidden_input")){
         input.classList.remove("hidden_input");
       }
       else {
         input.value="";
         input.classList.add("hidden_input");
       }
      },
      uploadImageSuccess(formData, index, fileList) {
        //console.log('data', formData, index, fileList)
        this.images = fileList;
        // //Upload image api
        // axios.post('http://your-url-upload', formData).then(response => {
        //   console.log(response)
        // })
      },
      beforeRemove (index, done, fileList) {
          done()
      },
      editImage (formData, index, fileList) {
        console.log('edit data', formData, index, fileList)
      }
    },
  };
  </script>
<style>
#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  width: 500px!important;
}

h1, h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
.required {
  font-weight: bolder;
  color :red;
  /* You can also add more styles here if needed */
}
</style>

  