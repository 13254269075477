<template>
    <div class="example-modal-content">
      <h1 >{{ details.buyer.user.name}}</h1>
      <p>{{ details.buyer.id }}</p>
    </div>
  </template>
  
  <script>
  export default {
    props: ['details']
  }
  </script>
  <style >
  .example-modal-content{
   width: 62.5em;
   
  }
  h1{
    font-size: larger;
  }
  .vm--modal  {
    /* position: fixed!important; */
   
    top: 50%!important;         /* Center vertically */
    left: 50%!important;        /* Center horizontally */
    transform: translate(-50%, -50%)!important; /* Adjust for centering */
    width: 60vw!important;    /* 90% of viewport width */
    height: 70vh!important;   /* 80% of viewport height */
    max-height: 100vh!important; /* Set a maximum height to maintain responsiveness */
    max-width: 100vw!important; 
    /* background-color: blue; */
    border-radius: 10px!important;
    box-shadow: 0 1px 30px 2px  rgba(199, 122, 14, 0.16)!important;
   
    /* margin-top :-6%; */
    padding: 20px;
    
  
  }
  </style>