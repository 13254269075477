<template>
<form class="p-5" @submit.prevent>
    <div class="space-y-12 p-5 bg-white">

      <div class="border-b border-gray-900/10 pb-12">
        <div class="flex flex-row justify-between">
        <h2 class="text-base font-semibold leading-7 text-gray-900">
          Commande
        </h2>
        <p >$90.00</p>
      </div>
        <p
          class="mt-1 text-sm leading-6 text-gray-600"
          style="
            font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; ;
          "
        >
          Les champs avec la signe <span class="required">*</span> sont obligatoires !
        </p>

        <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
       
        
          <div class="sm:col-span-3 mb-3">
            <label
              for="first-name"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Mode de Paiement <span class="required">*</span></label
            >
               <div class="mt-2" >
                <select v-model="command.payment_mode_id"
                name="payment_mode_id"
                        id="payment_mode_id"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in paymentmodes"
                :value="item.id"
                :key="item.id">
                <div>
               {{ item.mode_pay_fr }}</div>
                </option>
                </select>
                </div>
              </div>
              <div class="sm:col-span-3 mb-3">
            <label
              for="first-name"
              class="block text-sm font-medium leading-6 text-gray-900"
              >Mode de Livraison <span class="required">*</span></label
            >
               <div class="mt-2" >
                <select v-model="command.delivery_mode_id"
                name="delivery_mode_id"
                        id="delivery_mode_id"
                        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">
                <option
                v-for="item in deliverymodes"
                :value="item.id"
                :key="item.id">
                <div>
              <p> {{ item.mode_liv_fr }}  </p></div>
                </option>
                </select>
                </div>
              </div>

            

        
        </div>
        <div class="sm:col-span-3 mb-3 mt-6 " >
                <label class="block text-sm font-medium leading-6 text-gray-900">
                  Produits </label>
              </div>
          <div class="ml-28">
              <div class=" mt-8">
                      <div class="flow-root">
                        <ul role="list" class="-my-6 divide-y divide-gray-200">
                          <li v-for="(item, index) in productwithoutpriceByDevisId" :key="item.products.id" class="flex py-9">
                            <div class="h-28 w-32 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                              <img  style="height: 7rem!important;width: 8rem!important;" :src="(item.products.product.media && item.products.product.media.length>0)? (item.products.product.media[0].original_url?item.products.product.media[0].original_url: noe_image):noe_image"  />
                            </div>

                            <div class="ml-4 flex flex-1 flex-col">
                              <div>
                                <div class="flex flex-col lg:flex-row ">
                                    <div>
                                  <h1 class="font-black text-xl">

                                   {{ item.products.product.name_product }}
                                  </h1>
                                  <div class="mt-5 ml-3">
                                  <p class="text-xs ">Référence TAWDAW :<span class="font-bold text-xs ">  {{ item.products.product.reference_tawdaw }}</span></p>
                                  <p class="text-xs ">Référence du produit :<span class="font-bold text-xs ">  {{ item.products.product.reference_product }}</span></p>
                                  <p class="text-xs ">Description : {{ item.products.product.description }}</p>

                                </div>
                                </div>
                                 <div class="flex flex-1  justify-between text-sm">
                                   

                                 
                                </div>
                                <div class="flex flex-col mr-32">
                                    <div>
                                        <label for="last_name" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Prix du Produit</label>
                                        <input type="number"
                                         id="last_name"
                                         v-model="command.non_priced_products[index].price"
                                          class="block w-3/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required>
                                    </div>
                                   
                                    <div>
                                        <label for="last_name" class="block  mt-3 mb-2 text-sm font-medium text-gray-900 dark:text-white">Quantité </label>
                                        <input type="number"
                                         id="last_name" 
                                         v-model="command.non_priced_products[index].qte_cmd"
                                         class="block w-3/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  min="1" max="5" required>
                                    </div>
                                    <div>
                                        <input type="hidden"
                                         id="last_name"
                                         v-model="item.products.id"
                                         
                                          class="block w-3/5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" required>
                                    </div>
                                  </div>
                                </div>
                            
                              </div>
                              
                             
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>


      </div>
      
      

      
    </div>

    <div class="mt-6 flex items-center justify-end gap-x-6">
      <router-link to="/demandes/sdevis">
        <md-button @click="clean()"  
        style="background-color: #e0622a !important"
        >Annuler</md-button></router-link>
    
        <!-- <md-button @click="createCommand()"
        class="md-raised"
          style="background-color: #1476b5 !important"    >Créer</md-button> -->

          <md-button
        :class="{ 'disabled:opacity-70': isLoading }"
        @click="create()"
        type="submit"
        :disabled="isLoading"
        class="md-raised"
          style="background-color: #1476b5 !important"      >
        <span class="absolute inset-y-0 left-0 flex items-center pl-3">
          <svg
            v-if="isLoading"
            width="20"
            height="20"
            fill="currentColor"
            class="mr-2 animate-spin"
            viewBox="0 0 1792 1792"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
            ></path>
          </svg>
        </span>
        Créer
      </md-button>

   
    </div>
  </form>
  </template>
  
  <script>
  
  import { store } from "@/store/modules/GeneralStore";
import { Store } from 'vuex';
  
  export default {
    
    data() {
      return {
        buyer_id:null, 
        noe_image: require("@/assets/img/null.png"),
        currUser: null, 
      command: {
        date_cmd:null , 
        payment_mode_id :1, 
        delivery_mode_id :1, 
        non_priced_products:[],
      },
      DevisIds: null,
      };
    },
    computed: {
      isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    AnySuccess() {
      return store.state.success;
    },
      productwithoutpriceByDevisId() {
      return store.state.productwithoutpriceByDevisId;
    },
    paymentmodes() {
      return store.state.paymentmodes;
    },
    deliverymodes() {
      return store.state.deliverymodes;
    },
    },
  
    mounted: function () {
      //  this.currUser=store.state.user.id
       
      let context = this;
          this.DevisIds = JSON.parse(localStorage.getItem('selectedRowIds'));
        console.log('Selected row IDs:', this.DevisIds);
        let payload= {
          ids: this.DevisIds, 
          rt: true
        }
        store.dispatch('getProductWithoutPriceByDevisId',payload).then(function(){
          store.state.nonePricedProductsCount = context.productwithoutpriceByDevisId.length

          console.log("length", store.state.nonePricedProductsCount);
          console.log("products length", context.productwithoutpriceByDevisId[0].products.product.media[0]);
          console.log( 'this.id buyer ',context.productwithoutpriceByDevisId[0].buyer.id);
          context.buyer_id=context.productwithoutpriceByDevisId[0].buyer.id
          let test = store.getters["initNonpricedProducts"];
          context.command.non_priced_products = test;


        })
        store.dispatch('getPaymentModes').then (function (){
        })
        store.dispatch('getDeliveryModes') 
// console.log('this is buyer', this.buyer_id)


    },
    methods: {
      clean(){
        this.DevisIds= null; 
        localStorage.removeItem('selectedRowIds');
      },

      createCommand(){
        console.log('this is what we want',this.command.non_priced_products)

      },



      create() {
      let context = this;
     
      store.dispatch("addAdminCommand", {
        delivery_mode_id: this.command.delivery_mode_id,
        payment_mode_id: this.command.payment_mode_id,
       buyer_id:  this.buyer_id,
        non_priced_products:this.command.non_priced_products, 
        DevisIds: this.DevisIds
        
      }).then(function (){
        if (sessionStorage.getItem("commandadminSuccess")){
          context.$toasted.success(sessionStorage.getItem("commandadminSuccess")+" : <a style='color : black;margin-left: 10px' href='/acommandes/confirmees'>consulter la liste des commandes ici</a>",{
            duration: 5000,
            position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
            theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
            iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
          });
          sessionStorage.removeItem("commandadminSuccess");
          context.command= {
            delivery_mode_id: null,
            payment_mode_id:null,
            non_priced_products: [],
          };
         
        }
        if (sessionStorage.getItem("commandadminError")){
          context.$toasted.error(sessionStorage.getItem("commandadminError"),{
            duration: 5000,
            position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
            theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
            iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
          });
          sessionStorage.removeItem("commandadminError");
        }
      });
    },

    },
    created(){
 
    }
  };
  </script>
  
  <style >
  .required {
    font-weight: bolder;
    color :red;
    /* You can also add more styles here if needed */
  }
  
  </style>