// =========================================================
// * Vue Material Dashboard - v1.5.1
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-dashboard
// * Copyright 2023 Creative Tim (https://www.creative-tim.com)
// * Licensed under MIT (https://github.com/creativetimofficial/vue-material-dashboard/blob/master/LICENSE.md)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App";
// router setup
import Routes from "./routes/routes";

// Plugins
import GlobalComponents from "./globalComponents";
import GlobalDirectives from "./globalDirectives";
import Notifications from "./components/NotificationPlugin";

// MaterialDashboard plugin
import MaterialDashboard from "./material-dashboard";

import Chartist from "chartist";
import CKEditor from "ckeditor4-vue";
import VueLazyload from 'vue-lazyload'
import Toasted from 'vue-toasted';

import vmodal from 'vue-js-modal'
import './assets/css/global.css';  


Vue.use(vmodal)
Vue.use(Toasted, {
  duration: 1500
})
Vue.use(CKEditor);
Vue.use(VueLazyload)


import "./assets/css/main.css";
import store from "./store/modules/GeneralStore";

import JwPagination from "jw-vue-pagination";
Vue.component("jw-pagination", JwPagination);

// configure router
const router = new VueRouter({
  routes: Routes, // short for routes: routes
  mode: "history",
  linkExactActiveClass: "nav-item active",
});

Vue.prototype.$Chartist = Chartist;

Vue.use(VueRouter);
Vue.use(MaterialDashboard);
Vue.use(GlobalComponents);
Vue.use(GlobalDirectives);
Vue.use(Notifications);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  data: {
    Chartist: Chartist,
  },
  render: (h) => h(App),
  router: router,
});
