<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>



    </div>
    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
<!--     
      <router-link to= "/scommandes/confirmees/nouveau" >
          <md-button
              class="md-raised"
              style="background-color: #141456 !important"
          >Nouveau</md-button
          >
        </router-link> -->

        <md-card >
          <md-card-header style="background-color: #e0622a !important">
            <h4 class="title">Liste des commandes confirmées</h4>
          </md-card-header>

          <md-card-content>
            <!--            <simple-table :tableData="categories" ></simple-table>-->
            <div>
              <md-table style="text-align: center" v-model="confirmedadmincommands" table-header-color="orange">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell class="text-center"  md-label="Acheteur">
                    {{
                      item.buyer.user.name
                    }}</md-table-cell>
                     <md-table-cell class="text-center" md-label="Numéro de téléphone">{{
                      item.buyer.phone_number
                    }}</md-table-cell>
                    
                
                      <md-table-cell class="items-center " md-label="Détails du commande">
                          <router-link :to= "`/acommandes/confirmees/details/${item.id}`" class="m-auto">
                         <svg
                         style="color:black"
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" height="16" fill="currentColor"
                             class="bi bi-eye-fill m-auto" viewBox="0 0 16 16">
                           <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                           <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                          
                         </svg> </router-link>
                      
                  </md-table-cell>
                  <md-table-cell class="items-center" md-label="Status du Commande ">
               
               <div  class="m-auto" >
                 <p class="inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                        Confirmée
                         </p>
               </div>
                   
               </md-table-cell>

                <md-table-cell class="items-center" md-label="Status du Livraison ">
               
               <div  class="m-auto" >
                <select 
                v-model="item.confirmed_cmd.delivery_state" @change="updateBackend(item.confirmed_cmd)"
                class="block py-2.5 pr-10 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer">
                                <option value="">Confirmée</option>
                                <option value="pending">En cours de préparation</option>
                                <option value="prepared">Préparée</option>
                                <option value="pending_delivery">En cours de livraison </option>
                                <option value="delivered">Livrée</option>
                                <option value="canceled">Non livrée</option>
                                <option value="returned">Retournée</option>
                </select>
               </div>
                   
               </md-table-cell>
                 
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>

      </div>
</div>




  </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import DataTable from 'datatables.net-dt';
import ShowDetails from "@/pages//details/ShowDetails.vue"
import BuyerDetails from "@/pages//details/BuyerDetails.vue"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
import BuyerDetailsVue from '../details/BuyerDetails.vue';
export default {
  components: {
   
  },
  data() {
    return {
      // selectedStatus: "pending"
      delivery_state: ""
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    confirmedadmincommands() {
      return store.state.confirmedadmincommands;
    },
  },
  mounted: function () {
   let context= this;
    store.dispatch("getConfirmedAdminCommand", store.state.user.id).then(
        function () {
          console.log('confirmed commands admin  ',context.confirmedadmincommands); 
          for( let i=0 ; i<context.confirmedadmincommands.length; i++){
            if(context.confirmedadmincommands[i].confirmed_cmd.delivery_state=='without_state'){

              console.log('raha bla status ');
              context.confirmedadmincommands[i].confirmed_cmd.delivery_state="";
            }else{
              console.log('raha b sataus ');
            }
          }
          
        }
    );
   
  },
  methods: {
    updateBackend(item){
      console.log('this is item',item.command_id); 
      store.dispatch("updateDeliveryState", {
        delivery_state: item.delivery_state,
         command_id: item.command_id})

    },
    showProduct(data) {
      this.$modal.show(ShowDetails, {
        text: data
      })
    },
   
  },
};
</script>

