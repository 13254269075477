<template>
  <div class="content">
    <div class="md-layout">
      <div class="md-layout-item md-medium-size-100 md-size-50">
        <form>
          <md-card>
            <md-card-header  style="background-color: black!important;" data-background-color="black">
              <h4 class="title">Edit Email</h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Email Address</label>
                    <md-input v-model="emailadress" type="email"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <md-button style="background-color: #20187e!important;" class="md-raised md-primary">Update Profile</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>

      <div class="md-layout-item md-medium-size-100 md-size-50">
        <form>
          <md-card>
            <md-card-header  style="background-color: #F46428!important;"  data-background-color="#F05F22">
              <h4 class="title">Edit Password</h4>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-small-size-100 md-size-100">
                  <md-field>
                    <label>Email Address</label>
                    <md-input v-model="emailadress" type="password"></md-input>
                  </md-field>
                </div>
                <div class="md-layout-item md-size-100 text-right">
                  <md-button style="background-color: #20187e!important;" class="md-raised md-primary">Update Profile</md-button>
                </div>
              </div>
            </md-card-content>
          </md-card>
        </form>
      </div>


    </div>
  </div>
</template>

<script>
import { EditProfileForm, UserCard } from "@/pages";

export default {
  components: {
    EditProfileForm,
    UserCard,
  },
};
</script>
