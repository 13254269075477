<template>
  <div>
    <!-- ... -->
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {

    };
  }
};
</script>