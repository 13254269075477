<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 text-white"
    style="min-height: 100vh !important;background-color: black"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img class="mx-auto h-10 w-auto" :src="imgLogo" alt="Your Company" />
      <h2
        class="mt-10 text-center text-white text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Acceder a votre compte
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <form class="mt-8 space-y-6" @submit.prevent>
        <div
            style="background-color: orangered"
            v-if="AnyErrors"
          class="border-l-4 p-4"
        >
          <p class="font-bold text-black">
            {{ AnyErrors }}
          </p>
        </div>

        <input type="hidden" name="remember" value="true" />
        <div class="-space-y-px rounded-md shadow-sm">
          <div>
            <label for="email" class="sr-only text-white">Email</label>
            <input
              v-model="email"
              id="email"
              name="email"
              type="email"
              autocomplete=""
              class="mb-1 relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Email"
            />
          </div>
          <div>
            <label for="password" class="sr-only text-white"
              >Mot de passe</label
            >
            <input
              v-model="password"
              id="password"
              name="password"
              type="password"
              autocomplete="current-password"
              class="mt-1 relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="Mot de passe"
            />
          </div>
        </div>

        <div class="flex items-center justify-between">
          <!--          <div class="flex items-center">-->
          <!--            <input id="remember-me" name="remember-me" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />-->
          <!--            <label for="remember-me" class="ml-2 block text-sm text-gray-900">Remember me</label>-->
          <!--          </div>-->

          <!--          <div class="text-sm">-->
          <!--            <a-->
          <!--              href="#"-->
          <!--              style="color: white!important;"-->
          <!--              class="font-medium text-indigo-600 hover:text-indigo-500"-->
          <!--              >Mot de passe oublié ?</a-->
          <!--            >-->
          <!--          </div>-->
        </div>

        <div>
          <button
            :class="{ 'disabled:opacity-70': isLoading }"
            @click="Login"
            type="submit"
            class="disabled:opacity-70 group relative flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            :disabled="isLoading"
            style="background-color: #1476b5"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                v-if="isLoading"
                width="20"
                height="20"
                fill="currentColor"
                class="mr-2 animate-spin"
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
                ></path>
              </svg>
            </span>
            Connexion
          </button>
        </div>
        <div class="">
          Vous n'avez pas de compte ?
          <div id="nav">
            <router-link to="/inscription"
              ><h6
                style="
color: #F46428 !important;                  font-weight: bold !important;
                  text-transform: unset !important;
                "
              >
                Rejoigner-nous !
              </h6></router-link
            >
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import { store } from "../../store/modules/GeneralStore.js";

export default {
  props: {
    imgLogo: {
      type: String,
      default: require("@/assets/img/tawdaw-logo-1621898910.jpg"),
    },
  },
  name: "Login",
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
    };
  },
  methods: {
    Login() {
      let email = this.email;
      let password = this.password;
      store.dispatch("Login", { email, password });
    },
  },
};
</script>
