export default (to, from, next) => {
  let user = JSON.parse(sessionStorage.getItem("userData"));
  let can = false;
  for (let i=0 ;i<user.permissions.length;i++){
    if(user.permissions[i].id === 21){
      can = true;
    }
  }
  if (can){
    next();
  } else {
    next("/");
  }
};
