<template>
    <form class="p-5" @submit.prevent>
      <div class="space-y-12 p-5 bg-white">
<!--        <div-->
<!--          v-if="AnyErrors"-->
<!--          class="bg-red-200 border-red-600 text-red-600 border-l-4 p-4"-->
<!--          role="alert"-->
<!--        >-->
<!--          <p class="font-bold">Erreur !</p>-->
<!--          <p>-->
<!--            {{ AnyErrors }}-->
<!--          </p>-->
<!--        </div>-->
<!--  -->
<!--        <div-->
<!--          v-if="AnySuccess"-->
<!--          class="bg-green-200 border-green-600 text-green-600 border-l-4 p-4"-->
<!--          role="alert"-->
<!--        >-->
<!--          <p class="font-bold">Succée !</p>-->
<!--          <p>-->
<!--            {{ AnySuccess }}-->
<!--            <router-link :to="{ name: 'Marques / Confirmées' }">-->
<!--              consulter les marques ici</router-link-->
<!--            >-->
<!--          </p>-->
<!--        </div>-->
  
        <div class="border-b border-gray-900/10 pb-12">
          <h2 class="text-base font-semibold leading-7 text-gray-900">
            Marque
          </h2>
          <p
            class="mt-1 text-sm leading-6 text-gray-600"
            style="
              font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif; ;
            "
          >
            Les champs avec la signe <span class="required">*</span> sont obligatoires !
          </p>
  
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="sm:col-span-3">
              <label
                for="brand_name"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Nom de la marque  <span class="required">*</span></label
              >
              <div class="mt-2">
                <input
                  type="text"
                  v-model="brand.brand_name"
                  name="brand_name"
                  id="brand_name"
                  autocomplete="given-name"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
  
           
          </div>
        </div>
  
        <div class="border-b border-gray-900/10 pb-12">
          <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <label
                for="cover-photo"
                class="block text-sm font-medium leading-6 text-gray-900"
                >Image</label
              >
              <div
                class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10"
              >
                <div :hidden="brand.image_url !== null" class="text-center">
                  <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Importer une image</span>
                      <input
                        @change="onImageChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                  </div>
                  <br>
                  <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF</p>
                </div>


                <div :hidden="brand.image_url === null">
                  <img style="height: 300px !important" id="catImg" :src= "brand.image_url? brand.image_url : non_image" />
                     <div class="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      for="file-upload"
                      class="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span>Importer une image</span>
                      <input
                        @change="onImageChanged"
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        class="sr-only"
                      />
                    </label>
                    <!--                  <p class="pl-1">or drag and drop</p>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <div class="mt-6 flex items-center justify-end gap-x-6">
      
        <router-link :to="{ name: 'Marques / Confirmées' }">
          <md-button  >Annuler</md-button></router-link>
  
  
  
        <button
          :class="{ 'disabled:opacity-70': isLoading }"
          @click="create"
          type="submit"
          :disabled="isLoading"
          class="disabled:opacity-70 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <span class="absolute inset-y-0 left-0 flex items-center pl-3">
            <svg
              v-if="isLoading"
              width="20"
              height="20"
              fill="currentColor"
              class="mr-2 animate-spin"
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"
              ></path>
            </svg>
          </span>
          Modifier
        </button>
      </div>
    </form>
  </template>
  
  <script>
  
  import { store } from "@/store/modules/GeneralStore";
  
  export default {
    components: {
    
    },
    data() {
      return {
        id_ctg : null, 
        non_image: require("@/assets/img/null.png"),
      };
    },
    computed: {
      isLoading() {
        return store.state.isLoading;
      },
      AnyErrors() {
        return store.state.error;
      },
      AnySuccess() {
        return store.state.success;
      },
      brand() {
        return store.state.brand;
      },
    },
  
    mounted: function () {
        this.id_brand = this.$route.params.id;
        console.log(this.id_brand)
        let context=this; 
        store.dispatch('getbrandById',this.id_brand).then (function (){
            if(context.brand.image_url===""){
                context.brand.image_url= null;
            }
        });
       
    },
    methods: {
      onImageChanged(event) {
        this.brand.image_url = event.target.files[0];
        const image = this.brand.image_url;
        const reader = new FileReader();
        reader.onload = () => {
          const imgUrl = reader.result;
          document.getElementById("catImg").setAttribute("src", imgUrl);
          console.log(imgUrl);
          this.brand.image_url = imgUrl;
          //img.src = imgurl;
        };
        reader.readAsDataURL(image);
      },
      create() {
        let context = this;
        console.log(this.brand.image_url);
        store.dispatch("updateBrand", {
            brand_name: this.brand.brand_name,
          image_url: this.brand.image_url,
          id: this.brand.id
        }).then(function (){
          if (sessionStorage.getItem("brandSuccess")){
            context.$toasted.success(sessionStorage.getItem("brandSuccess")+" : <a style='color : black;margin-left: 10px' href='/marques/confirmees'>consulter la liste des marques confirmées ici</a>",{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("brandSuccess");
          }
          if (sessionStorage.getItem("brandError")){
            context.$toasted.error(sessionStorage.getItem("brandError"),{
              duration: 5000,
              position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
              theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
              iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
            });
            sessionStorage.removeItem("brandError");
          }
        });
      },
    },
  };
  </script>
  
  <style >
  .required {
    font-weight: bolder;
    color :red;
    /* You can also add more styles here if needed */
  }
  
  </style>