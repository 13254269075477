<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>



    </div>
    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
<!--     
      <router-link to= "/scommandes/confirmees/nouveau" >
          <md-button
              class="md-raised"
              style="background-color: #141456 !important"
          >Nouveau</md-button
          >
        </router-link> -->

        <md-card >
          <md-card-header style="background-color: #e0622a !important">
            <h4 class="title">Liste des commandes confirmées</h4>
          </md-card-header>

          <md-card-content>
            <!--            <simple-table :tableData="categories" ></simple-table>-->
            <div>
              <md-table style="text-align: center"  v-model="confirmedsellercommands" table-header-color="orange">
                <md-table-row slot="md-table-row" slot-scope="{ item,index }">
                  <md-table-cell class="text-center" md-label="Acheteur">
                    {{
                      item.buyer.user.name
                    }}</md-table-cell>
                     <md-table-cell class="text-center" md-label="Numéro de téléphone">{{
                      item.buyer.phone_number
                    }}</md-table-cell>
                    
                
                      <md-table-cell class="text-center" md-label="Détails du commande">
                          <router-link :to= "`/scommandes/confirmees/details/${item.id}`" class="m-auto">
                         <svg
                         style="color:black"
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" height="16" fill="currentColor"
                             class="bi bi-eye-fill m-auto" viewBox="0 0 16 16">
                           <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                           <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                          
                         </svg> </router-link>
                      
                  </md-table-cell>
                  <md-table-cell class="items-center" md-label="Status du Commande ">
               
               <div class="m-auto" >
                 <p class="inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                        Confirmée
                         </p>
               </div>
                   
               </md-table-cell>


               <md-table-cell class="items-center" md-label="Status du Livraison ">
<!--                
               <div  v-if="item.confirmed_cmd.delivery_state === 'without_state'" class="m-auto" >
                 <p  class="inline-flex rounded-full  py-1 px-3 text-sm font-medium text-success">
                        ---
                        
                         </p>
               </div> -->
     




               <div  class="m-auto" >
                        <p :id="'badge_'+(item.id)"   :class="((item.confirmed_cmd.delivery_state === 'without_state')?'inline-flex rounded-full  py-1 px-3 text-sm font-medium text-success':(item.confirmed_cmd.delivery_state=='canceled' || item.confirmed_cmd.delivery_state=='returned')?'inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-danger':
                        (item.confirmed_cmd.delivery_state=='prepared'?'inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-success':(!thereisnt[index]?'inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-success':
                        'inline-flex rounded-full bg-[#918755] bg-opacity-10 py-1 px-3 text-sm font-medium text-yellow-500'
                        )))">
                            {{(item.confirmed_cmd.delivery_state === 'without_state')?'Confirmée':( item.confirmed_cmd.delivery_state=='canceled' ?'Non livrée':( item.confirmed_cmd.delivery_state=='returned'?'Retournée':
                        (item.confirmed_cmd.delivery_state=='prepared'?'Préparée':(!thereisnt[index]?'Préparée':
                        'En cours de préparation'
                        )))) }}
                         </p>
               </div>


















       
           
               </md-table-cell>

               <md-table-cell class="items-center" md-label=" ">
               
                
              <div :id="'checkbox_'+(item.id)"  @click="markedPrepared(item, index)"
                  v-if=" item.confirmed_cmd.delivery_state=='pending' && thereisnt[index] ">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-square" viewBox="0 0 16 16">
                 
                  <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z"/>
                  <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                </svg>
              </div>
               </md-table-cell>


                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>

      </div>
</div>




  </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import DataTable from 'datatables.net-dt';
import ShowDetails from "@/pages//details/ShowDetails.vue"
import BuyerDetails from "@/pages//details/BuyerDetails.vue"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
import BuyerDetailsVue from '../details/BuyerDetails.vue';
export default {
  components: {
   
  },
  data() {
    return {
      state: "En cours de préparation",
      s:[], 
      class:"",
      thereisnt:[], 
      // currentAdmin: null,
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    confirmedsellercommands() {
      return store.state.confirmedsellercommands;
    },
  },
  mounted: function () {
   let context= this;
    store.dispatch("getConfirmedSellerCommand", store.state.user.id).then(
        function () {
          console.log('salam',context.confirmedsellercommands)
          for(let i=0; i< context.confirmedsellercommands.length; i++){
            
            if (context.confirmedsellercommands[i].confirmed_cmd.sellersIds_prepared?.includes(store.state.user.id)) {
             context.thereisnt.push(false)
            //  context.progress.push((context.confirmedsellercommands[i].confirmed_cmd.sellersIds_prepared / context.pendingsellercommands[i].resume.count_sellers)* 100 ) ; 
            
            }else{
              context.thereisnt.push(true)
              // context.progress.push(0) ; 

            }
          //   if (context.thereisnt[i]==false)  {
          //     document.getElementById('badge_'+item.id).innerHTML='Préparée'
          // document.getElementById('badge_'+item.id).classList.remove('text-yellow-500');
          // document.getElementById('badge_'+item.id).classList.add('text-success');
          //   }
          }
        }
    );
   
  },
  methods: {

    markedPrepared(item,index){
      let context = this 
      store.dispatch("updateDeliveryStateSeller", {
        user_id: store.state.user.id,
         command_id: item.id})
         .then(function (){
          // for(let i=0; i< context.confirmedsellercommands.length; i++){
            console.log("this.is index",context.thereisnt[index])
          context.thereisnt[index]==false
          
            document.getElementById('checkbox_'+item.id).style.display='none';
          document.getElementById('badge_'+item.id).innerHTML='Préparée'
          document.getElementById('badge_'+item.id).classList.remove('text-yellow-500');
          document.getElementById('badge_'+item.id).classList.add('text-success');

          
          

          


        // }

         })


    //  this.state="prepare"
    //  this.s="prepare"
    //  for(let i=0; i< this.confirmedsellercommands.length; i++){
    //   // this.confirmedsellercommands[]
    //  }
    },
       toggleStyle() {
      this.isClicked = !this.isClicked;
    },
   
  },
};
</script>

