export default (to, from, next) => {
  let user = JSON.parse(sessionStorage.getItem("userData"));
  if (sessionStorage.getItem("token")) {
    if (user.roles[0].id===3) {
      sessionStorage.clear();
      window.location = "/Login";
    }
    else{
      next();
    }
  } else {
    next("/Login");
  }
};
