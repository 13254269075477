<template>
  <div class="content">
    <div v-if="isLoading" class="flex flex-col flex-1 gap-5 sm:p-2">
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>
      <div class="flex flex-col flex-1 gap-3">
        <div class="w-full bg-gray-200 animate-pulse h-14 rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
        <div class="w-full h-3 bg-gray-200 animate-pulse rounded-2xl"></div>
      </div>



    </div>
    <div v-else class="md-layout">
      <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
      >
    


        <md-card >
          <md-card-header style="background-color: #e0622a !important">
            <h4 class="title">Liste des commandes à confirmer</h4>
          </md-card-header>

          <md-card-content>
            <!--            <simple-table :tableData="categories" ></simple-table>-->
            <div>
              <md-table  style="text-align: center" v-model="pendingadmincommands" table-header-color="orange">
                <md-table-row slot="md-table-row" slot-scope="{ item }">
                  <md-table-cell  md-label="Acheteur">{{
                      item.buyer.user.name
                    }}</md-table-cell>
                     <md-table-cell  md-label="Numéro de téléphone">{{
                      item.buyer.phone_number
                    }}</md-table-cell>
                     <!-- <md-table-cell  md-label="Propriétère du commande">
                      <div >
                      {{item.cmd_products.product.creator.phone_number}}
                    </div>
                    <div>
                      {{item.cmd_products.product.creator.phone_number}}
                    </div>
                    
                    </md-table-cell> -->
                 
                      <md-table-cell  class="items-center " md-label="Détails du commande">
                        <router-link  class="m-auto"  :to= "`/acommandes/pourconfirmer/details/${item.id}`" >
                         <svg
                         
                         style="color:black"
                            xmlns="http://www.w3.org/2000/svg" 
                            width="16" height="16" fill="currentColor"
                             class="bi bi-eye-fill m-auto" viewBox="0 0 16 16">
                           <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                           <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                          
                         </svg> </router-link>
                  </md-table-cell>
          
                  <md-table-cell class="text-center" md-label=" Progress du confirmation ">
                      <div   class="w-full">
                      <div  
                       class="flex justify-between mb-1">
                      <span class="text-sm font-medium text-green-90 dark:text-white">{{  item.resume.confirmation_level}} / {{ item.resume.count_sellers }}</span>
                    </div>
                    <div class="w-full bg-green-900 rounded-full h-2.5 dark:bg-green-900">
                      <div class="bg-green-600 h-2.5 rounded-full"   style="   width: progress[index]%"></div>
                    </div>
                  </div>
                  
                  <div    class="w-full">
                      <div  
                       class="flex justify-between mb-1">
                      <span class="text-sm font-medium text-red-800 dark:text-white">{{ JSON.parse(item.resume.refuse_sellers ).length }} / {{ item.resume.count_sellers }}</span>
                    </div>
                    <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                      <div class="bg-red-600 h-2.5 rounded-full"   style="   width: progressrefused[index]%"></div>
                    </div>
                  </div>



                
                  </md-table-cell>
                  <md-table-cell class="items-center" md-label="  ">
                    <md-button

                    style="background-color: #e0622a!important"
                            @click="Confirm(item.id)" class="md-dense md-raised md-primary m-auto">
                              Confirmer
                    
                    </md-button>




                      <md-button

                            style="background-color: #1476b5 !important"
                            @click="Refuse(item.id)" class="md-dense md-raised md-primary m-auto">
                              Refuser
                            
                      </md-button>

                    </md-table-cell>
                 
                </md-table-row>
              </md-table>
            </div>
          </md-card-content>
        </md-card>

      </div>
</div>




  </div>
</template>

<script>
import { store } from "../../store/modules/GeneralStore.js";
import DataTable from 'datatables.net-dt';
import ShowDetails from "@/pages//details/ShowDetails.vue"
import BuyerDetails from "@/pages//details/BuyerDetails.vue"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-dt/css/jquery.dataTables.css"
import BuyerDetailsVue from '../details/BuyerDetails.vue';
export default {
  components: {
   
  },
  data() {
    return {
      progress:[], 
      thereisnt:[], 
      refused:[],
      progressrefused:[],
    };
  },
  computed: {
    isLoading() {
      return store.state.isLoading;
    },
    AnyErrors() {
      return store.state.error;
    },
    pendingadmincommands() {
      return store.state.pendingadmincommands;
    },
  },
  mounted: function () {
   let context= this;
   if (sessionStorage.getItem("pendingadmincommandsSuccess")){
        context.$toasted.success(sessionStorage.getItem("pendingadmincommandsSuccess"),{
          duration: 5000,
          position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
          theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
          iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
        });
        sessionStorage.removeItem("pendingadmincommandsSuccess");
      }
    if (sessionStorage.getItem("pendingadmincommandsError")){
      context.$toasted.error(sessionStorage.getItem("pendingadmincommandsError"),{
        duration: 5000,
        position: 'top-right', // ['top-right', 'top-center', 'top-left', 'bottom-right', 'bottom-center', 'bottom-left']
        theme: 'bubble', // ['toasted-primary', 'outline', 'bubble']
        iconPack: 'mdi', // ['material', 'fontawesome', 'mdi', 'custom-class', 'callback']
      });
      sessionStorage.removeItem("pendingadmincommandsError");
    }
    store.dispatch("getPendingAdminCommand", store.state.user.id).then(
        function () {
          console.log('salam',context.pendingadmincommands);
          for(let i=0; i< context.pendingadmincommands.length; i++){
            
            if (context.pendingadmincommands[i].resume.sellers_ids.includes(store.state.user.id)) {
             context.thereisnt.push(false)
             context.progress.push((context.pendingadmincommands[i].resume.confirmation_level / context.pendingadmincommands[i].resume.count_sellers)* 100 ) ; 
            
            }else{
              context.thereisnt.push(true)
              console.log('this is  no accept the length of hhh',)

              context.progress.push(0) ; 

            }


            if (context.pendingadmincommands[i].resume.refuse_sellers.includes(store.state.user.id)) {
             context.refused.push(false)
             console.log('this is the length of hhh',)
             context.progressrefused.push((JSON.parse(context.pendingadmincommands[i].resume.refuse_sellers ).length/ context.pendingadmincommands[i].resume.count_sellers)* 100 ) ; 
            
            }else{
              context.refused.push(true)
              console.log('this is  no refuse the length of hhh',)
              context.progressrefused.push(0) ; 

            }

          }
          console.log(context.thereisnt)
        }
    );
   
  },
  methods: {
    showProduct(data) {
      this.$modal.show(ShowDetails, {
        text: data
      })
    },
    Confirm(id){
      store.dispatch("ConfirmAdminCommand",id).then(
        function () {
                  window.location = "/acommandes/pourconfirmer";
          }  
      );
      // console.log('this is id command to confirm ',id )
    }, 
    Refuse(id){
      let context = this
      store.dispatch("refuseAdminCommand",id)
      .then(
        function () {
                  window.location = "/acommandes/pourconfirmer";
              
                //  console.log("this is resume",context.cmdResume);
          
          }
      );
    
    }
  
  },
};
</script>

